import React, { useMemo, Fragment } from 'react';
import { useDrop, useDrag } from 'react-dnd';
import ReactDOMServer from 'react-dom/server';
import { browserHistory } from 'react-router';
import moment from 'moment';
import _ from 'lodash';
import { statusColorBtn } from '../../../new_module/utils/data';
import BookingTableBookingGap from './BookingTableBookingGap';

const scale = 90;

export const BookingTableBookingRow = ({
	table,
	newDate,
	diningTime,
	startDrag,
	DraggingEnd,
	dragBooking,
	dragTableName,
	droppedTable,
}) => {
	const [ { isOver, canDrop }, drop ] = useDrop({
		accept: 'bookingCell',
		canDrop: () => {
			const dropSeatIndex = dragBooking.seats.map((seat) => seat.table_number).indexOf(table.table_number);
			return dropSeatIndex === -1;
		},
		drop: () => {
			if (dragTableName === table.table_number) return;
			DraggingEnd({ table });
			// console.log('tableName-----------', tableInfo);
		},
		collect: (monitor) => ({
			isOver: monitor.isOver({ shallow: true }),
			canDrop: monitor.canDrop()
		})
	});

	const renderBookings = () => {
		const newTimeline = _.sortBy(table.timeline, [ 'service_start_time' ]);
		return newTimeline.map((booking, index) => {
			return (
				<BookingTableBookingCell
					key={booking.id}
					bookingIndex={index}
					booking={booking}
					newDate={newDate}
					tableName={booking.target_seat.table_number}
					startDrag={startDrag}
				/>
			);
		});
	};

	const renderGaps = () => {
		const newTimeline = _.sortBy(table.timeline, [ 'service_start_time' ]);
		return newTimeline.map((booking, idx) => {
			const time = new moment(booking['service_start_time']);
			const duration = moment.duration(time.diff(newDate));
			const left = duration.asHours() * 2 * scale + scale + 2;

			if (newTimeline[idx + 1]) {
				const current = moment(booking['service_start_time'], 'YYYY-MM-DD HH:mm:ss').add(
						booking['dining_time'],
						'minutes'
					),
					next = moment(newTimeline[idx + 1]['service_start_time'], 'YYYY-MM-DD HH:mm');
				const booking_duration = next.diff(current, 'minutes', true);

				if (booking_duration > 30 && booking_duration <= diningTime) {
					return (
						<BookingTableBookingGap
							key={'gap' + idx}
							dining_time={booking.dining_time}
							booking_duration={booking_duration}
							left={left}
							table={table}
						/>
					);
				}
				return null;
			}
			return null;
		});
	};

	let backgroundColor = '#fff';

	if (!canDrop && isOver) {
		backgroundColor = '#fff';
	} else if (isOver) {
		backgroundColor = 'rgba(0, 100, 0, .5)';
	}

	if (table.table_number === droppedTable.table_number) {
		backgroundColor = 'rgba(0, 100, 0, .5)';
	}

	const TimeCellMemo = useMemo(() => {
		return _.range(50).map((cell) => {
			return <div key={cell} className="spaceCell" />;
		});
	}, []);

	return (
		<div ref={drop} style={{ backgroundColor }} className="tableRow">
			{renderBookings()}
			{renderGaps()}
			{TimeCellMemo}
		</div>
	);
};

const BookingTableBookingCell = ({ booking, newDate, tableName, bookingIndex, startDrag }) => {
	const time = new moment(booking['service_start_time']);
	const duration = moment.duration(time.diff(newDate));
	const style = {
		left: duration.asHours() * 2 * scale + scale + 2,
		width: booking.dining_time * 3 - 5,
		backgroundColor: booking.warning ? 'red' : statusColorBtn[booking.status]
	};
	const BookingID = `${booking.id}_${tableName}`;
	const [ { opacity }, drag ] = useDrag({
		item: { id: BookingID, type: 'bookingCell' },
		begin() {
			startDrag({ booking, tableName, bookingIndex });
		},
		collect: (monitor) => {
			return {
				opacity: monitor.isDragging() ? 0.4 : 1
			};
		}
	});

	const rednerBookingContent = () => {
		if (parseInt(booking.dining_time, 10) >= 30) {
			return (
				<Fragment>
					<div className="bookingTime">{booking.service_start_time.slice(11, 16)}</div>
					<div className="a">{booking.name}</div>
					<div className="bookingCell-attendance">{booking.attendance}人</div>
					{booking.seats.length > 1 && (
						<div className="b combineIcon">
							<img src={require('../../../../images/component/combine.svg')} alt="combine" />
						</div>
					)}
				</Fragment>
			);
		}
	};

	const renderTooltip = (booking) => {
		let time = new moment(booking['service_start_time']);
		const date = moment(new Date(browserHistory.getCurrentLocation().query.date)).format('YYYY-MM-DD');

		return ReactDOMServer.renderToStaticMarkup(
			<div className="booking-popover">
				<div className="info_1">{time.format('YYYY-MM-DD HH:mm')}</div>
				<div className="info_name">
					<p>
						{booking.name}({booking.attendance}位)
					</p>
					<p>
						<i className="fa fa-phone" aria-hidden="true" /> {booking.phone_number}
					</p>
				</div>
				{booking.source !== 'offline' && <div className="info_seatArea">{booking.space_group_selection_t}</div>}
				<div className="info_table">
					<img src={require('../../../../images/table_02_booking_timeline_icon.svg')} alt="table" />
					{booking.seats.map((seat, index) => {
						return (
							<span key={index} className="info_seats">
								{seat.table_number}
							</span>
						);
					})}
				</div>
				{booking.memo !== '' && booking.memo !== null ? <div className="info_memo">{booking.memo}</div> : ''}
				<div className="info_setStatusBtn">
					<span
						className="clickable"
						id="edit_modal_status"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<span
							style={{
								color: statusColorBtn[booking['status']]
							}}
						>
							●
						</span>{' '}
						{booking['status_t']}
					</span>
					<div
						className="dropdown-menu"
						aria-labelledby="edit_modal_status"
						dangerouslySetInnerHTML={{
							__html: BookingStatusDropdown(booking, date)
						}}
					/>
				</div>
				<div className="textRight info_create">
					建立預約：{booking.created_at}
					<span className="editBooking" data-content={JSON.stringify(booking)}>
						<img src={require('../../../../images/edit.svg')} alt="edit" />
					</span>
				</div>
			</div>
		);
	};

	return (
		<div
			ref={drag}
			style={{
				...style,
				opacity
			}}
			className="bookingCell"
			data-toggle="popover"
			data-placement="bottom"
			data-content={renderTooltip(booking)}
		>
			{rednerBookingContent()}
		</div>
	);
};

function BookingStatusDropdown(booking, date) {
	const today = moment().format('YYYY/MM/DD');
	const pre = moment(new Date(booking.booking_datetime)).add(-60, 'm').format('YYYY/MM/DD HH:mm');
	const tomorrow = moment(new Date(today)).add(1, 'd').format('YYYY/MM/DD HH:mm');
	let hideFutureStatus = false; //完成消費
	let hidePastStatus = false; //已到店
	let hideStatus = false; //未到店 已入座

	let currentTime = moment();

	if (currentTime.isBefore(new Date(date), 'date')) {
		//未來: 隔天
		//待確認 確認預約 已到店 取消預約
		hideFutureStatus = true;
		hidePastStatus = false;
		hideStatus = true;
	} else if (currentTime.isAfter(new Date(date), 'date')) {
		//過去: 前一天
		//待確認 確認預約 取消預約 完成預約
		hideFutureStatus = false;
		hidePastStatus = true;
		hideStatus = true;
	} else if (currentTime.isBefore(new Date(pre))) {
		//當天時間>預約60分鐘
		//待確認 確認預約 已到店 取消預約
		hideFutureStatus = true;
		hidePastStatus = false;
		hideStatus = true;
	} else if (currentTime.isBetween(new Date(pre), new Date(tomorrow), null, '[)')) {
		//當天時間<=預約60分鐘
		//待確認 確認預約 已到店 取消預約 未到店 已入座 完成消費
		hideFutureStatus = false;
		hidePastStatus = false;
		hideStatus = false;
	}

	const hideCommon = hideStatus ? 'none' : '';
	const hideFuture = hideFutureStatus ? 'none' : '';
	const hidePast = hidePastStatus ? 'none' : '';
	const hideDudoo = booking.status === 'seated' && booking.pos_sync_required ? "none" : ""

	const bookingStr = JSON.stringify(booking);

	return `
		<ul>
			<li style='display: ${hideDudoo}' onClick='window.setStatus(${bookingStr}, "unconfirmed")' >
				<a style='background-color: ${statusColorBtn['unconfirmed']}'>待確認</a>
			</li>
			<li style='display: ${hideDudoo}' onClick='window.setStatus(${bookingStr}, "confirmed")' >
				<a style='background-color: ${statusColorBtn['confirmed']}'>確認預約</a>
			</li>
				<li  onClick='window.setStatus(${bookingStr}, "cancel")'' >
				<a style='background-color: ${statusColorBtn['cancel']}'>取消預約</a>
			</li>
			<li style='display: ${hideCommon}' onClick='window.setStatus(${bookingStr}, "no_show")' >
				<a style='background-color: ${statusColorBtn['no_show']}'>未到店</a>
			</li>
			<li style='display: ${hidePast === "none" || hideDudoo === "none" ? "none" : ""}' onClick='window.setStatus(${bookingStr}, "show")' >
				<a style='background-color: ${statusColorBtn['show']}'>已到店</a>
			</li>
			<li style='display:  ${hidePast === "none" || hideDudoo === "none" ? "none" : ""}' onClick='window.seatedWarning(${bookingStr}, "seated")' >
				<a style='background-color: ${statusColorBtn['seated']}'>已入座</a>
			</li>
			<li style='display: ${hideFuture}' onClick='window.setStatus(${bookingStr}, "finish")' >
				<a style='background-color: ${statusColorBtn['finish']}'>完成消費</a>
			</li>
		</ul>
	`;
}

export default BookingTableBookingRow;
