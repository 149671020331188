import React, { useCallback } from 'react';
import { useDrop } from 'react-dnd';
import _ from 'lodash';

import { time } from '../utils/data';

import BookingCell from './BookingCell';


export default ({
	date,
	tableInfo,
	droppedTable,
	closeCanvasTableBookingInfo,
	DraggingEnd,
	setBookingListInfo,
	startDrag,
	dragTableName,
	dragBooking,
	setSelectBookings
}) => {
	const [ { isOver, canDrop }, drop ] = useDrop({
		accept: 'bookingCell',
		canDrop: ()=>{
			const dropSeatIndex = dragBooking.seats.map(seat=> {return seat.table_number}).indexOf(tableInfo.table_number)
			return dropSeatIndex === -1
		},
		drop: () => {
			if(dragTableName === tableInfo.table_number) return;
		
			DraggingEnd({ tableInfo });
			// console.log('tableName-----------', tableInfo);
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		})
	});

	const renderTimelineBookingRowCell = useCallback(
		() => {
			return _.range(49).map((cell, index) => {
				const hour = time[cell] ? time[cell] : '00:00';
				return (
					<div className="spaceCell" key={index} data-time={hour} onClick={() => closeCanvasTableBookingInfo()} />
				);
			});
		},
		[ closeCanvasTableBookingInfo ]
	);

	const renderBookingCell = useCallback(
		(timeline, tableNumber) => {
			const newTimeline = _.sortBy(timeline, [ 'service_start_time' ]);

			return newTimeline.map((booking, index) => {
				return (
					<BookingCell
						key={index}
						bookingIndex={index}
						date={date}
						booking={booking}
						tableName={tableNumber}
						setBookingListInfo={setBookingListInfo}
						startDrag={startDrag}
						setSelectBookings={setSelectBookings}
					/>
				);
			});
		},
		[date, setBookingListInfo, startDrag, setSelectBookings]
	);

	let backgroundColor = '#fff';

	if(!canDrop && isOver){
		backgroundColor = '#fff';
	}else if(isOver){
		backgroundColor = 'rgba(0, 100, 0, .5)';
	}


	if (tableInfo.table_number === droppedTable.table_number) {
		backgroundColor = 'rgba(0, 100, 0, .5)';
	}
	

	return (
		<div ref={drop} style={{ backgroundColor }} className={`timelineBookingRow`}>
			{renderTimelineBookingRowCell()}
			{renderBookingCell(tableInfo.timeline, tableInfo.table_number)}
		</div>
	);
};

