import React, { useEffect, useState, useCallback } from 'react';
import { Link, browserHistory } from 'react-router';
import { config } from '../../utils/config';

import '../../stylesheets/allSetting.css';
import '../booking_settings/bookingSettingsPopup/bookingSettingPopup.scss';

import MenuCouponApi from '../menu_coupon/MenuCouponApi';
const menuCouponAPI = new MenuCouponApi();

const settingsItem = [
	{
		title: '主要設定',
		subPage: [
			{
				title: '商家基本資訊',
				path: '/dashboard/setting/shops'
			},
			{
				title: '肚肚POS串接設定',
				path: '/dashboard/setting/integratewithdudooeat'
			}
		]
	},
	{
		title: '座位設定',
		subPage: [
			{
				title: '座位設定',
				path: '/dashboard/setting/seats'
			},
			{
				title: '座位排序設定',
				path: '/dashboard/setting/seats_order'
			},
			{
				title: '座位圖設定',
				path: '/dashboard/setting/tablemap_setting'
			}
		]
	},
	{
		title: '預約系統設定',
		subPage: [
			{
				title: '線上預約設定',
				path: '/dashboard/setting/enable'
			},
			{
				title: '預約時段設定',
				path: '/dashboard/setting/available_time'
			},
			{
				title: '例外預約日期設定',
				path: '/dashboard/setting/date_config2'
			},
			{
				title: '不可預約日期設定',
				path: '/dashboard/setting/date_config'
			},
			{
				title: '預先收款功能',
				path: '/dashboard/setting/payment'
			},
			{
				title: '預約組別設定',
				path: '/dashboard/setting/area'
			},
			{
				title: '自訂預約問卷',
				path: '/dashboard/setting/custom_question'
			},
			{
				title: '預約通知設定',
				path: '/dashboard/setting/sms'
			},
			{
				title: 'Google日曆同步設定',
				path: '/dashboard/setting/calendar'
			},
			{
				title: 'Google預訂設定',
				path: '/dashboard/setting/google-reservation'
			}
		]
	},
	{
		title: '排隊系統設定',
		subPage: [
			{
				title: '線上排隊設定',
				path: '/dashboard/setting/display'
			},
			{
				title: '排隊組別設定',
				path: '/dashboard/setting/group'
			},
			{
				title: '預計等候時間設定',
				path: '/dashboard/setting/time'
			},
			{
				title: '排隊通知設定',
				path: '/dashboard/setting/notification'
			}
		]
	}
];

const AllSettingSideBar = ({ children }) => {
	const [ showBackArea, setShowBackArea ] = useState(false);
	const [ page, setPage ] = useState({});
	const [ showReport, setShowReport ] = useState(false);

	const resizeSreen = useCallback(() => {
		let timer;
		if (timer) clearTimeout(timer);
		timer = setTimeout(() => {
			let w_w = window.innerWidth;
			if (w_w >= 768) {
				hideApplicationMenu();
			}
		}, 200);
	}, []);

	useEffect(
		() => {
			const state = browserHistory.getCurrentLocation().state;
			let backToPage = {};
			let $menushopNav = document.getElementsByClassName('menushop-nav')[0];
			window.addEventListener('resize', resizeSreen);

			if(state !== undefined){
				const {source, mode, date}=state;
				backToPage['source'] = source
				backToPage['mode'] = mode !== undefined? mode : ''
				backToPage['date'] = date
			}

			
			if (Object.keys(backToPage).length !== 0) {
				setShowBackArea(true);
				setPage(backToPage);
			}

			return () => {
				$menushopNav.removeAttribute('style');
				window.removeEventListener('resize', resizeSreen);
			};
		},
		[ resizeSreen ]
	);

	useEffect(() => {
		let isMounted = true;
		const getShowReport = async () => {
			try {
				const data = await menuCouponAPI.getShops();
				if (isMounted) {
					setShowReport(data.shop.show_report);
				}
			} catch (err) {
				console.log('get shop name err-----', err);
			}
		};
	
		getShowReport();
	
		return () => {
			isMounted = false;
		};
	}, []);

	const renderBackArea = () => {
		const {source, mode, date} = page;

		if (source === 'newModule') {
			return (
				<div className="newAllSettingSideBar__backArea">
					<Link to={{pathname: '/dashboard/new_module' ,query: {date}}}>
						<button>回預約排隊系統</button>
					</Link>
				</div>
			);
		} else if (source === 'booking') {
			return (
				<div className="newAllSettingSideBar__backArea">
					<Link to={{pathname: '/dashboard/bookings' ,query: {date, mode}}}>
						<button>回預約系統</button>
					</Link>
				</div>
			);
		} else if (source === 'queue') {
			return (
				<div className="newAllSettingSideBar__backArea">
					<Link to={'/dashboard/queue_system'}>
						<button>回排隊系統</button>
					</Link>
				</div>
			);
		} else if (source ==='crm_customer'){
			return (
				<div className="newAllSettingSideBar__backArea">
					<Link to={'/dashboard/customers'}>
						<button>回顧客關係管理</button>
					</Link>
				</div>
			);
		} else if (page ==='coupon'){
			return (
				<div className="newAllSettingSideBar__backArea">
					<Link to={'/dashboard/menu_coupon'}>
						<button>回優惠券系統</button>
					</Link>
				</div>
			);
		}
	};

	const renderReport = () => {
		// 於特定店家後台才會顯示
		if(showReport) {
			return (
				<div className="pageBlock_single">
					<Link to={'/dashboard/setting/report'}>
						<h2 className="pageBlockIcon_5">匯出數據報表</h2>
					</Link>
				</div>
			)
		}
	};

	const renderAdvanced = () => {
		if (config.env !== 'production') {
			return (
				<div className="pageBlock">
					<Link to={'/dashboard/setting/advanced'}>
						<h2 className="pageBlockIcon_5">進階設定(Dev Only)</h2>
					</Link>
				</div>
			);
		}
	};

	const showApplicationMenu = () => {
		const $menushopNav = document.getElementsByClassName('menushop-nav')[0];
		const $newAllSettingSideBar = document.getElementsByClassName('newAllSettingSideBar')[0];
		const $applicationMenuIcon = document.getElementsByClassName('m-applicationMenuIcon')[0];
		const $closeApplicationMenuIcon = document.getElementsByClassName('m-closeApplicationMenuIcon')[0];
		const $layout = document.getElementsByClassName('m-applicationMenu__layout')[0];
		const $newAllSetting = document.getElementsByClassName('newAllSetting')[0];

		$newAllSetting.classList.add('newAllSettingFixed');
		$newAllSettingSideBar.classList.add('showSideBar');
		$applicationMenuIcon.classList.add('hideIcon');
		$closeApplicationMenuIcon.classList.add('showIcon');
		$layout.classList.add('showLayout');
		$menushopNav.style.backgroundColor = 'rgba(118, 187, 108, 1)';
	};

	const hideApplicationMenu = () => {
		const $menushopNav = document.getElementsByClassName('menushop-nav')[0];
		const $newAllSettingSideBar = document.getElementsByClassName('newAllSettingSideBar')[0];
		const $applicationMenuIcon = document.getElementsByClassName('m-applicationMenuIcon')[0];
		const $closeApplicationMenuIcon = document.getElementsByClassName('m-closeApplicationMenuIcon')[0];
		const $layout = document.getElementsByClassName('m-applicationMenu__layout')[0];
		const $newAllSetting = document.getElementsByClassName('newAllSetting')[0];

		$newAllSetting.classList.remove('newAllSettingFixed');
		$newAllSettingSideBar.classList.remove('showSideBar');
		$applicationMenuIcon.classList.remove('hideIcon');
		$closeApplicationMenuIcon.classList.remove('showIcon');
		$layout.classList.remove('showLayout');
		$menushopNav.removeAttribute('style');
	};

	const toSettingItem = (path) => {
		hideApplicationMenu();
		browserHistory.push({
			pathname: path
		});
	};

	return (
		<div className="newAllSetting">
			<aside className="newAllSettingSideBar">
				<div className="newAllSettingSideBar__radius">
					<div className="newAllSettingSideBarWrap">
						{showBackArea && renderBackArea()}
						<div className="newAllSettingSideBar__pages">
							{settingsItem.map((page, index) => {
								let pageBlockIcon = 'pageBlockIcon_' + index;
								return (
									<div className="pageBlock" key={index}>
										<h2 className={pageBlockIcon}>{page.title}</h2>
										<ul>
											{page.subPage.map((item, index) => {
												return (
													<li
														key={index}
														className="pageBlock__item"
														onClick={() => toSettingItem(item.path)}
													>
														{item.title}
													</li>
												);
											})}
										</ul>
									</div>
								);
							})}
							<div className="pageBlock_single">
								<Link to={'/dashboard/setting/users'}>
									<h2 className="pageBlockIcon_5">人員帳號設定</h2>
								</Link>
							</div>
							{ renderReport() }
							{ renderAdvanced() }
						</div>
					</div>
				</div>
			</aside>
			<div className="m-applicationMenu__layout" />
			<div className="newAllSettingDisplay">
				<div className="newAllSettingDisplay__radius reservation-settings">{children}</div>
			</div>

			<i className="m-menuIcon m-applicationMenuIcon" onClick={() => showApplicationMenu()} />
			<i className="m-menuIcon m-closeApplicationMenuIcon" onClick={() => hideApplicationMenu()} />
		</div>
	);
};

export default AllSettingSideBar;
