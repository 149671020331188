import React, { Fragment } from 'react'
import $ from 'jquery'
import _ from 'lodash'
import { browserHistory } from 'react-router'

import { config } from '../../utils/config'
import {handleError} from '../../libs/handler'
import { LineNotifyRebindModal, LineNotifyUnbindModal } from './Msg/BookingSettingMsgLineNotifyModal';
import './bookingSetting.scss';

var createReactClass = require('create-react-class');

const msgTypeTab = {
	email: 'E-mail',
	line: 'LINE',
	sms: '簡訊'
};

export default class extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      init: false,
      isSaved: true,
      disableMsg: false,
      depositDisabled: false,
      merchant_verified: null,
      spg_merchant_id: null,
      spg_hash_key: null,
      spg_hash_iv: null,
      sms_enable_condition: {},
      sending: false,
      email_notification_settings: {},
      sms_notification_settings: {},
      line_notify_settings: {},
      smsBookingCreatedMsg: '',
      smsBookingReminderMsg: '',
      smsBookingCanceledMsg: '',
      smsBookingDepositMsg: '',
      cursorPosition: 0,
      insertTextarea: 'created', // 檢查是否為欲嵌入的輸入框
      shortenUrl: 'MNUSHOP_SHORTEN_URL ', // 最後的空格防止有英文文案被誤認為是網址的一部分
      defaultShortenUrl: 'MNUSHOP_SHORTEN_URL',
      showUrl: '*/店家網址/*',
      bookingTime: 'MNUSHOP_BOOKING_DATETIME',
      showTime: '*/預約時間/*',
      depositTime: 'DEPOSIT_DUE_DATE',
      showDepositTime: '*/付款期限/*',
      defaultSmsBookingCreatedMsg: '',
      defaultSmsBookingReminderMsg: '',
      defaultSmsBookingCanceledMsg: '',
      defaultSmsBookingDepositMsg: '',
      bookingCreatedTab: 'email',
      bookingReminderTab: 'email',
      bookingCanceledTab: 'email',
      showLineNotifyRebindModal: false,
      showLineNotifyUnbindModal: false,
      apParams: undefined,
      selectedPointsOption: null,
      customPoints: null,
      showCustomPointsError: false,
    }
  }

  routerWillLeave = (nextLocation) => {
    // return false to prevent a transition w/o prompting the user,
    // or return a string to allow the user to decide:
    // return `null` or nothing to let other hooks to be executed
    //
    // NOTE: if you return true, other hooks will not be executed!
    if(!this.state.isSaved) return '您剛剛做的更動尚未儲存，請問您是否要離開?'
  }

  // change = (e) => {
  //   //var name = e.target.name
  //   var val = e.target.value
  //   this.setState({
  //       sms_enable : val,
  //       isSaved: false,
  //   })
  // }

  buyMsgPoint = (e, isCustom = false) => {
    let value = e.target.value;

    if(
      parseInt(value, 10) < 100 ||
      isNaN(value)
    ){ 
      this.setState({apParams: undefined});
    }

    // 自訂
    if(isCustom) this.setState({customPoints: parseInt(value, 10)});
		if(value === 'custom' || isCustom) {
      this.setState({selectedPointsOption: 'custom'});
		} else {
      this.setState({selectedPointsOption: parseInt(value, 10)});
		}

    this.setState({showCustomPointsError: false});

    $('#apForm').html('');

    $.ajax({url: window.domain + '/dashboard/booking_settings/buy_msg_point', 
    method: 'POST',
    data: {
      amount: value === 'custom' ? this.state.customPoints : value,
      category: 'booking'
    },
    xhrFields: { withCredentials: true }})
    .done(function(data){        
      $('#apForm').html('');
      //debugger
      for(let k in data){ 
        if(data.hasOwnProperty(k))
          $('#apForm').append('<input name="' + k + '" type="hidden" value="' + data[k] + '">')
      }
      this.setState({apParams: data});
    }.bind(this))
    .fail(function(xhr){
      handleError(xhr);
    });
  }

  submitBuyMsgPoint = () => {
    // 自訂點數小於 100
		if(
			this.state.selectedPointsOption === 'custom' &&
			(this.state.customPoints < 100 || !this.state.customPoints)
		) return this.setState({showCustomPointsError: true});

    if(this.state.apParams !== undefined && this.state.selectedPointsOption){
      document.getElementById('apForm').submit();

      this.setState({
        selectedPointsOption: null,
        customPoints: null,
        showCustomPointsError: true,
        apParams: undefined
      });
    } else {
      return window.app.alert.setMessage("請先選擇欲購買項目","tip")
    }
  }

  componentDidMount(){
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
    $.ajax({url: window.domain + '/dashboard/booking_settings', 
          method: 'GET', 
          xhrFields: { withCredentials: true }})
      .done(function(data){
        const shortenUrl = this.state.shortenUrl;
        const defaultShortenUrl = this.state.defaultShortenUrl;
        const showUrl = this.state.showUrl;
        const bookingTime = this.state.bookingTime;
        const showTime = this.state.showTime;
        const depositTime = this.state.depositTime;
        const showDepositTime = this.state.showDepositTime;
        const regShortenUrl = /MNUSHOP_SHORTEN_URL /g; // 最後的空格防止有英文文案被誤認為是網址的一部分
        const regDefaultShortenUrl = /MNUSHOP_SHORTEN_URL/g;
        const regBookingTime = /MNUSHOP_BOOKING_DATETIME/g;
        const regDepositTime = /DEPOSIT_DUE_DATE/g;

        let disable = false;
        if(data.shop.msg_point <= 0) {
          disable = true;
        }
        this.setState(data);
        this.setState({init: true, sms_enable_condition: JSON.parse(data.sms_enable_condition), disableMsg: disable}, ()=>   $('[data-toggle="tooltip"]').tooltip());
        this.setState({
          smsBookingCreatedMsg: data.sms_notification_settings.booking_created.msg.replace(regShortenUrl, showUrl).replace(regDefaultShortenUrl, showUrl).replace(regBookingTime, showTime),
          smsBookingReminderMsg: data.sms_notification_settings.booking_reminder.msg.replace(regDefaultShortenUrl, showUrl).replace(regDefaultShortenUrl, showUrl).replace(regBookingTime, showTime),
          smsBookingCanceledMsg: data.sms_notification_settings.booking_canceled.msg.replace(regDefaultShortenUrl, showUrl).replace(regDefaultShortenUrl, showUrl).replace(regBookingTime, showTime),
          smsBookingDepositMsg: data.sms_notification_settings.booking_deposit.msg.replace(regShortenUrl, showUrl).replace(regDefaultShortenUrl, showUrl).replace(regDepositTime, showDepositTime),
          defaultSmsBookingCreatedMsg: data.default_text.sms_notification_settings.booking_created.replace(defaultShortenUrl, showUrl).replace(bookingTime, showTime),
          defaultSmsBookingReminderMsg: data.default_text.sms_notification_settings.booking_reminder.replace(defaultShortenUrl, showUrl).replace(bookingTime, showTime),
          defaultSmsBookingCanceledMsg: data.default_text.sms_notification_settings.booking_canceled.replace(defaultShortenUrl, showUrl).replace(bookingTime, showTime),
          defaultSmsBookingDepositMsg: data.default_text.sms_notification_settings.booking_deposit.replace(defaultShortenUrl, showUrl).replace(depositTime, showDepositTime),
        })

        // 付款設定
        this.setState({
          spg_merchant_id: data.spg_merchant_id,
          spg_hash_key: data.spg_hash_key,
          spg_hash_iv: data.spg_hash_iv
        })
      }.bind(this))
      .fail(function(xhr){
        handleError(xhr);
      });

    // 付款通知
		$.ajax({url: window.domain + '/dashboard/booking_settings/spg_data',
			method: 'GET',
			xhrFields: { withCredentials: true }})
		.done(function(data){
			this.setState({ merchant_verified: data.merchant_verified })
		}.bind(this))
		.fail(function(xhr){
			console.log('get credit enabled error ----------')
		});

    // 綁定 line notify
    if (this.props.location.query.code !== undefined){
      this.initLineNotifyToken(this.props.location.query.code);
    }

    if(this.props.location.query.error !== undefined) {
      window.app.alert.setMessage("連動錯誤請重新再試", "error");
      browserHistory.push(window.location.pathname);
    }
  }

  initLineNotifyToken = (code) => {
    $.ajax({url: window.domain + '/dashboard/line_notify_code', 
      data: {
        code
      },
      method: 'POST',
      xhrFields: { withCredentials: true }})
    .done((data) => {
      window.app.alert.setMessage("已與 LINE Notify 完成連動", "done");
      this.updateLineNotifyBindStatus();
      browserHistory.push(window.location.pathname);
    })
    .fail(function(xhr){
      handleError(xhr);
      window.app.alert.setMessage("連動錯誤請重新再試", "error");
      browserHistory.push(window.location.pathname);
    });
  }

  handleSmsNotificationEnabled = (type_1, type_2) => {
    let { sms_notification_settings } = this.state;

    sms_notification_settings[type_1][type_2] = !sms_notification_settings[type_1][type_2]
    this.setState({sms_notification_settings})
  }

  handleEmailNotificationEnabled = (type) => {
    let { email_notification_settings } = this.state;

    email_notification_settings[type].enabled = !email_notification_settings[type].enabled;
    this.setState({ email_notification_settings });
  }

  handleEmailNotificationNote = (e, type) => {
    const value = e.target.value;
    let { email_notification_settings } = this.state;

    email_notification_settings[type].note = value;
    this.setState({ email_notification_settings, isSaved: false });
  }

  handleLineNotificationEnabled = (type) => {
    let { line_notify_settings } = this.state;

    line_notify_settings[type].enabled = !line_notify_settings[type].enabled;
    this.setState({ line_notify_settings });
  }

  handleLineNotificationNote = (e, type) => {
    const value = e.target.value;
    let { line_notify_settings } = this.state;

    line_notify_settings[type].note = value;
    this.setState({ line_notify_settings, isSaved: false });
  }

  addEmail = () => {
    var email = this.refs.email.value;
    this.setState({booking_notification_email: this.state.booking_notification_email + ';' + email}, 
                  ()=>this.setState({isSaved: false}))
    this.refs.email.value = ""
  }

  removeEmail = (idx) => {
    var emailArr = this.state.booking_notification_email.split(';')
    emailArr.splice(idx,1);
    this.setState({booking_notification_email: _.compact(emailArr).join(';')},
                   ()=>this.setState({isSaved: false}))
  }

  // 檢查字數
  checkMsgLength = (msg, insertType, msgType) => {
    const shopNameLength = 
      this.state.shop.display_name.length + this.state.shop.branch.length;
    // shorten url
    const shortenUrlLength = this.state.shortenUrl.length;
    const showUrl = this.state.showUrl;
    const showUrlLength = this.state.showUrl.length;
    // booking time
    const showTime = this.state.showTime;
    const showTimeLength = this.state.showTime.length;
    const bookingTimeLength = 11;
    // deposit time
    const showDepositTime = this.state.showDepositTime;
    const showDepositTimeLength = this.state.showDepositTime.length;
    const depositTimeLength =  11;
    // insert times
    let insertShortenUrlTimes = msg.split(showUrl).length - 1;
    let insertBookingTime = msg.split(showTime).length - 1;
    let insertDepositTime = msg.split(showDepositTime).length - 1;

    if(msg === this.state.smsBookingDepositMsg) {
      let msgLength_deposit = msg.length +
      insertShortenUrlTimes * (shortenUrlLength - showUrlLength) +
      insertDepositTime * (depositTimeLength - showDepositTimeLength);

      if(insertType === 'shopName') {
        if(msgLength_deposit + shopNameLength < 336) {
          this.handleInsertShopName(msgType);
        }
      } else if(insertType === 'bookingTime') {
        if(msgLength_deposit + depositTimeLength < 336) {
          this.handleInsertBookingTime(msgType)
        }
      } else if(insertType === 'url') {
        if(msgLength_deposit + shortenUrlLength < 336) {
          this.handleInsertUrl(msgType)
        }
      }
    } else {
      let msgLength_booking = msg.length +
      insertShortenUrlTimes * (shortenUrlLength - showUrlLength) +
      insertBookingTime * (bookingTimeLength - showTimeLength);

      if(insertType === 'shopName') {
        if(msgLength_booking + shopNameLength < 336) {
          this.handleInsertShopName(msgType);
        }
      } else if(insertType === 'bookingTime') {
        if(msgLength_booking + bookingTimeLength < 336) {
          this.handleInsertBookingTime(msgType)
        }
      } else if(insertType === 'url') {
        if(msgLength_booking + shortenUrlLength < 336) {
          this.handleInsertUrl(msgType)
        }
      }
    }
  }

  // 嵌入店家名稱
  handleInsertShopName = (type) => {
    const smsBookingCreatedMsg = this.state.smsBookingCreatedMsg;
    const smsBookingReminderMsg = this.state.smsBookingReminderMsg;
    const smsBookingCanceledMsg = this.state.smsBookingCanceledMsg;
    const smsBookingDepositMsg = this.state.smsBookingDepositMsg;
    const shopName = this.state.shop.display_name;
    const branch = this.state.shop.branch;

    const cursorPosition = this.state.cursorPosition;
    const newCursorPosition = cursorPosition + shopName.length + branch.length;
    const lastPosition_created = this.state.smsBookingCreatedMsg.length;
    const lastPosition_reminder = this.state.smsBookingReminderMsg.length;
    const lastPosition_canceled = this.state.smsBookingCanceledMsg.length;
    const lastPosition_deposit = this.state.smsBookingDepositMsg.length;
    

    if(type === 'created') {
      // 若"點擊按鈕的輸入框"和"insertTextarea"不同，則嵌入在最後面的位置
      if(this.state.insertTextarea !== 'created') {
        this.setState({
          smsBookingCreatedMsg: smsBookingCreatedMsg.slice(0, lastPosition_created) + shopName + branch + smsBookingCreatedMsg.slice(lastPosition_created),
          cursorPosition: lastPosition_created + shopName.length + branch.length
        });
      } else {
        this.setState({
          smsBookingCreatedMsg: smsBookingCreatedMsg.slice(0, cursorPosition) + shopName + branch + smsBookingCreatedMsg.slice(cursorPosition),
          cursorPosition: newCursorPosition 
        });
      }
    } else if (type === 'reminder') {
      if(this.state.insertTextarea !== 'reminder') {
        this.setState({
          smsBookingReminderMsg: smsBookingReminderMsg.slice(0, lastPosition_reminder) + shopName + branch + smsBookingReminderMsg.slice(lastPosition_reminder),
          cursorPosition: lastPosition_reminder + shopName.length + branch.length
        });
      } else {
        this.setState({
          smsBookingReminderMsg: smsBookingReminderMsg.slice(0, cursorPosition) + shopName + branch + smsBookingReminderMsg.slice(cursorPosition),
          cursorPosition: newCursorPosition 
        });
      }
    } else if (type === 'canceled') {
      if(this.state.insertTextarea !== 'canceled') {
        this.setState({
          smsBookingCanceledMsg: smsBookingCanceledMsg.slice(0, lastPosition_canceled) + shopName + branch + smsBookingCanceledMsg.slice(lastPosition_canceled),
          cursorPosition: lastPosition_canceled + shopName.length + branch.length 
        });
      } else {
        this.setState({
          smsBookingCanceledMsg: smsBookingCanceledMsg.slice(0, cursorPosition) + shopName + branch + smsBookingCanceledMsg.slice(cursorPosition),
          cursorPosition: newCursorPosition 
        });
      }
    } else {
      // deposit
      if(this.state.insertTextarea !== 'deposit') {
        this.setState({
          smsBookingDepositMsg: smsBookingDepositMsg.slice(0, lastPosition_deposit) + shopName + branch + smsBookingDepositMsg.slice(lastPosition_deposit),
          cursorPosition: lastPosition_deposit + shopName.length + branch.length 
        });
      } else {
        this.setState({
          smsBookingDepositMsg: smsBookingDepositMsg.slice(0, cursorPosition) + shopName + branch + smsBookingDepositMsg.slice(cursorPosition),
          cursorPosition: newCursorPosition 
        });
      }
    }
  }

  // 嵌入預約時間
  handleInsertBookingTime = (type) => {
    const smsBookingCreatedMsg = this.state.smsBookingCreatedMsg;
    const smsBookingReminderMsg = this.state.smsBookingReminderMsg;
    const smsBookingCanceledMsg = this.state.smsBookingCanceledMsg;
    const smsBookingDepositMsg = this.state.smsBookingDepositMsg;
    const bookingTime = this.state.showTime;
    const depositTime = this.state.showDepositTime;

    const cursorPosition = this.state.cursorPosition;
    const newCursorPosition = cursorPosition + bookingTime.length;
    const newCursorPosition_deposit = cursorPosition + depositTime.length;
    const lastPosition_created = this.state.smsBookingCreatedMsg.length;
    const lastPosition_reminder = this.state.smsBookingReminderMsg.length;
    const lastPosition_canceled = this.state.smsBookingCanceledMsg.length;
    const lastPosition_deposit = this.state.smsBookingDepositMsg.length;

    if(type === 'created') {
      // 若"點擊按鈕的輸入框"和"insertTextarea"不同，則嵌入在最後面的位置
      if(this.state.insertTextarea !== 'created') {
        this.setState({
          smsBookingCreatedMsg: smsBookingCreatedMsg.slice(0, lastPosition_created) + bookingTime + smsBookingCreatedMsg.slice(lastPosition_created),
          cursorPosition: lastPosition_created + bookingTime.length
        });
      } else {
        this.setState({
          smsBookingCreatedMsg: smsBookingCreatedMsg.slice(0, cursorPosition) + bookingTime + smsBookingCreatedMsg.slice(cursorPosition),
          cursorPosition: newCursorPosition 
        });
      }
    } else if (type === 'reminder') {
      if(this.state.insertTextarea !== 'reminder') {
        this.setState({
          smsBookingReminderMsg: smsBookingReminderMsg.slice(0, lastPosition_reminder) + bookingTime + smsBookingReminderMsg.slice(lastPosition_reminder),
          cursorPosition: lastPosition_reminder + bookingTime.length
        });
      } else {
        this.setState({
          smsBookingReminderMsg: smsBookingReminderMsg.slice(0, cursorPosition) + bookingTime + smsBookingReminderMsg.slice(cursorPosition),
          cursorPosition: newCursorPosition 
        });
      }
    } else if (type === 'canceled') {
      if(this.state.insertTextarea !== 'canceled') {
        this.setState({
          smsBookingCanceledMsg: smsBookingCanceledMsg.slice(0, lastPosition_canceled) + bookingTime + smsBookingCanceledMsg.slice(lastPosition_canceled),
          cursorPosition: lastPosition_canceled + bookingTime.length 
        });
      } else {
        this.setState({
          smsBookingCanceledMsg: smsBookingCanceledMsg.slice(0, cursorPosition) + bookingTime + smsBookingCanceledMsg.slice(cursorPosition),
          cursorPosition: newCursorPosition 
        });
      }
    } else {
      // deposit
      if(this.state.insertTextarea !== 'deposit') {
        this.setState({
          smsBookingDepositMsg: smsBookingDepositMsg.slice(0, lastPosition_deposit) + depositTime + smsBookingDepositMsg.slice(lastPosition_deposit),
          cursorPosition: lastPosition_deposit + depositTime.length 
        });
      } else {
        this.setState({
          smsBookingDepositMsg: smsBookingDepositMsg.slice(0, cursorPosition) + depositTime + smsBookingDepositMsg.slice(cursorPosition),
          cursorPosition: newCursorPosition_deposit 
        });
      }
    }
  }

  // 嵌入進度網址
  handleInsertUrl = (type) => {
    const smsBookingCreatedMsg = this.state.smsBookingCreatedMsg;
    const smsBookingReminderMsg = this.state.smsBookingReminderMsg;
    const smsBookingCanceledMsg = this.state.smsBookingCanceledMsg;
    const smsBookingDepositMsg = this.state.smsBookingDepositMsg;
    const showUrl = this.state.showUrl;

    const cursorPosition = this.state.cursorPosition;
    const newCursorPosition = cursorPosition + showUrl.length;
    const lastPosition_created = this.state.smsBookingCreatedMsg.length;
    const lastPosition_reminder = this.state.smsBookingReminderMsg.length;
    const lastPosition_canceled = this.state.smsBookingCanceledMsg.length;
    const lastPosition_deposit = this.state.smsBookingDepositMsg.length;

    if(type === 'created') {
      // 若"點擊按鈕的輸入框"和"insertTextarea"不同，則嵌入在最後面的位置
      if(this.state.insertTextarea !== 'created') {
        this.setState({
          smsBookingCreatedMsg: smsBookingCreatedMsg.slice(0, lastPosition_created) + showUrl + smsBookingCreatedMsg.slice(lastPosition_created),
          cursorPosition: lastPosition_created + showUrl.length
        });
      } else {
        this.setState({
          smsBookingCreatedMsg: smsBookingCreatedMsg.slice(0, cursorPosition) + showUrl + smsBookingCreatedMsg.slice(cursorPosition),
          cursorPosition: newCursorPosition 
        });
      }
    } else if (type === 'reminder') {
      if(this.state.insertTextarea !== 'reminder') {
        this.setState({
          smsBookingReminderMsg: smsBookingReminderMsg.slice(0, lastPosition_reminder) + showUrl + smsBookingReminderMsg.slice(lastPosition_reminder),
          cursorPosition: lastPosition_reminder + showUrl.length
        });
      } else {
        this.setState({
          smsBookingReminderMsg: smsBookingReminderMsg.slice(0, cursorPosition) + showUrl + smsBookingReminderMsg.slice(cursorPosition),
          cursorPosition: newCursorPosition 
        });
      }
    } else if (type === 'canceled') {
      if(this.state.insertTextarea !== 'canceled') {
        this.setState({
          smsBookingCanceledMsg: smsBookingCanceledMsg.slice(0, lastPosition_canceled) + showUrl + smsBookingCanceledMsg.slice(lastPosition_canceled),
          cursorPosition: lastPosition_canceled + showUrl.length 
        });
      } else {
        this.setState({
          smsBookingCanceledMsg: smsBookingCanceledMsg.slice(0, cursorPosition) + showUrl + smsBookingCanceledMsg.slice(cursorPosition),
          cursorPosition: newCursorPosition 
        });
      }
    } else {
      if(this.state.insertTextarea !== 'deposit') {
        this.setState({
          smsBookingDepositMsg: smsBookingDepositMsg.slice(0, lastPosition_deposit) + showUrl + smsBookingDepositMsg.slice(lastPosition_deposit),
          cursorPosition: lastPosition_deposit + showUrl.length 
        });
      } else {
        this.setState({
          smsBookingDepositMsg: smsBookingDepositMsg.slice(0, cursorPosition) + showUrl + smsBookingDepositMsg.slice(cursorPosition),
          cursorPosition: newCursorPosition 
        });
      }
    }
  }

  handleCursorPosition = (type) => {
    let cursorPosition = document.getElementById('smsBookingNote_' + type).selectionStart;
    this.setState({
      cursorPosition: cursorPosition,
      insertTextarea: type
    });
  };

  handleSmsMsg = (type, e) => {
    // shorten url
    const shortenUrlLength = this.state.shortenUrl.length;
    const showUrl = this.state.showUrl;
    const showUrlLength = this.state.showUrl.length;
    // booking time
    const showTime = this.state.showTime;
    const showTimeLength = this.state.showTime.length;
    const bookingTimeLength = 11;
    // deposit time
    const showDepositTime = this.state.showDepositTime;
    const showDepositTimeLength = this.state.showDepositTime.length;
    const depositTimeLength = 11;
    
    let value = e.target.value;
    let insertShortenUrlTimes = value.split(showUrl).length - 1;
    let insertBookingTime = value.split(showTime).length - 1;
    let newNoteLength = 
      value.length +
      insertShortenUrlTimes * (shortenUrlLength - showUrlLength) +
      insertBookingTime * (bookingTimeLength - showTimeLength);
    // deposit
    let insertDepositTime = value.split(showDepositTime).length - 1;
    let newNoteLength_deposit = 
      value.length +
      insertShortenUrlTimes * (shortenUrlLength - showUrlLength) +
      insertDepositTime * (depositTimeLength - showDepositTimeLength);

    if(type === 'created') {
      if(newNoteLength < 336) {
        this.setState({
          smsBookingCreatedMsg: value,
          isSaved: false
        })
      }
    } else if(type === 'reminder') {
      if(newNoteLength < 336) {
        this.setState({
          smsBookingReminderMsg: value,
          isSaved: false
        })
      }
    } else if(type === 'canceled') {
      if(newNoteLength < 336) {
        this.setState({
          smsBookingCanceledMsg: value,
          isSaved: false
        })
      }
    } else {
      // deposit
      if(newNoteLength_deposit < 336) {
        this.setState({
          smsBookingDepositMsg: value,
          isSaved: false
        })
      }
    }
  };

  renderEmailCreatedText = () => {
    return (
      <div className="emailTextBlock">
        <div className="emailTextTile">自訂文字備註內容（選填）</div>
        <textarea
          className="emailTextarea"
          value={this.state.email_notification_settings.booking_created.note}
          placeholder="輸入預約建立備註內容"
          onChange={e => { this.handleEmailNotificationNote(e, 'booking_created') }}
        />
      </div>
    )
  }

  renderEmailReminderText = () => {
    return (
      <div className="emailTextBlock">
        <div className="emailTextTile">自訂文字備註內容（選填）</div>
        <textarea
          className="emailTextarea"
          value={this.state.email_notification_settings.booking_reminder.note}
          placeholder="輸入預約提醒備註內容"
          onChange={e => { this.handleEmailNotificationNote(e, 'booking_reminder') }}
        />
      </div>
    )
  }

  renderEmailCanceledText = () => {
    return (
      <div className="emailTextBlock">
        <div className="emailTextTile">自訂文字備註內容（選填）</div>
        <textarea
          className="emailTextarea"
          value={this.state.email_notification_settings.booking_canceled.note}
          placeholder="輸入取消預約備註內容"
          onChange={e => { this.handleEmailNotificationNote(e, 'booking_canceled') }}
        />
      </div>
    )
  }

  renderLineCreatedText = () => {
    return (
      <div className="lineTextBlock">
        <div className="lineTextTile">自訂文字備註內容（選填）</div>
        <textarea
          className="lineTextarea"
          value={this.state.line_notify_settings.booking_created.note}
          placeholder="輸入預約建立備註內容"
          onChange={e => { this.handleLineNotificationNote(e, 'booking_created') }}
        />
      </div>
    )
  };

  renderLineReminderText = () => {
    return (
      <div className="lineTextBlock">
        <div className="lineTextTile">自訂文字備註內容（選填）</div>
        <textarea
          className="lineTextarea"
          value={this.state.line_notify_settings.booking_reminder.note}
          placeholder="輸入預約建立備註內容"
          onChange={e => { this.handleLineNotificationNote(e, 'booking_reminder') }}
        />
      </div>
    )
  };

  renderLineCanceledText = () => {
    return (
      <div className="lineTextBlock">
        <div className="lineTextTile">自訂文字備註內容（選填）</div>
        <textarea
          className="lineTextarea"
          value={this.state.line_notify_settings.booking_canceled.note}
          placeholder="輸入預約建立備註內容"
          onChange={e => { this.handleLineNotificationNote(e, 'booking_canceled') }}
        />
      </div>
    )
  };

  renderNumberOfCharacters = (type) => {
    // shorten url
    const shortenUrlLength = this.state.shortenUrl.length;
    const showUrl = this.state.showUrl;
    const showUrlLength = this.state.showUrl.length;
    // booking time
    const showTime = this.state.showTime;
    const showTimeLength = this.state.showTime.length;
    const bookingTimeLength = 11;
    // deposit time
    const showDepositTime = this.state.showDepositTime;
    const showDepositTimeLength = this.state.showDepositTime.length;
    const depositTimeLength =  11;

    if(type === 'created') {
      let insertShortenUrlTimes = this.state.smsBookingCreatedMsg.split(showUrl).length - 1;
      let insertBookingTime = this.state.smsBookingCreatedMsg.split(showTime).length - 1;
      let defaultNoteLength = 
      this.state.defaultSmsBookingCreatedMsg.length - showUrlLength + shortenUrlLength -
      showTimeLength + bookingTimeLength;
      let newNoteLength = 
        this.state.smsBookingCreatedMsg.length +
        insertShortenUrlTimes * (shortenUrlLength - showUrlLength) +
        insertBookingTime * (bookingTimeLength - showTimeLength);

      if(this.state.smsBookingCreatedMsg.substring(this.state.smsBookingCreatedMsg.length - showUrlLength) === showUrl) {
        newNoteLength --;
      };

      if(this.state.smsBookingCreatedMsg.length === 0) {
        let letters = Math.ceil(defaultNoteLength / 75);

        return <div className="countText">預估{defaultNoteLength}字 / {letters}封</div>
      } else {
        let letters = 0;

        if(newNoteLength <= 70) {
          letters = 1;
        } else if(newNoteLength >= 71 && newNoteLength <= 134) {
          letters = 2;
        } else if(newNoteLength >= 135 && newNoteLength <= 201) {
          letters = 3;
        } else if(newNoteLength >= 202 && newNoteLength <= 268) {
          letters = 4;
        } else if(newNoteLength >= 269 && newNoteLength <= 335) {
          letters = 5;
        };

        return <div className="countText">預估{newNoteLength}字 / {letters}封</div>
      }
    } else if(type === 'reminder') {
      let insertShortenUrlTimes = this.state.smsBookingReminderMsg.split(showUrl).length - 1;
      let insertBookingTime = this.state.smsBookingReminderMsg.split(showTime).length - 1;
      let defaultNoteLength = 
      this.state.defaultSmsBookingReminderMsg.length - showUrlLength + shortenUrlLength -
      showTimeLength + bookingTimeLength;
      let newNoteLength = 
        this.state.smsBookingReminderMsg.length +
        insertShortenUrlTimes * (shortenUrlLength - showUrlLength) +
        insertBookingTime * (bookingTimeLength - showTimeLength);

      if(this.state.smsBookingReminderMsg.substring(this.state.smsBookingReminderMsg.length - showUrlLength) === showUrl) {
        newNoteLength --;
      };

      if(this.state.smsBookingReminderMsg.length === 0) {
        let letters = Math.ceil(defaultNoteLength / 75);
        return <div className="countText">預估{defaultNoteLength}字 / {letters}封</div>
      } else {
        let letters = 0;

        if(newNoteLength <= 70) {
          letters = 1;
        } else if(newNoteLength >= 71 && newNoteLength <= 134) {
          letters = 2;
        } else if(newNoteLength >= 135 && newNoteLength <= 201) {
          letters = 3;
        } else if(newNoteLength >= 202 && newNoteLength <= 268) {
          letters = 4;
        } else if(newNoteLength >= 269 && newNoteLength <= 335) {
          letters = 5;
        };

        return <div className="countText">預估{newNoteLength}字 / {letters}封</div>
      }
    } else if(type === 'canceled') {
      let insertShortenUrlTimes = this.state.smsBookingCanceledMsg.split(showUrl).length - 1;
      let insertBookingTime = this.state.smsBookingCanceledMsg.split(showTime).length - 1;
      let defaultNoteLength = this.state.defaultSmsBookingCanceledMsg.length - showTimeLength + bookingTimeLength;
      let newNoteLength = 
        this.state.smsBookingCanceledMsg.length +
        insertShortenUrlTimes * (shortenUrlLength - showUrlLength) +
        insertBookingTime * (bookingTimeLength - showTimeLength);

      if(this.state.smsBookingCanceledMsg.substring(this.state.smsBookingCanceledMsg.length - showUrlLength) === showUrl) {
        newNoteLength --;
      };

      if(this.state.smsBookingCanceledMsg.length === 0) {
        let letters = Math.ceil(defaultNoteLength / 75);
        return <div className="countText">預估{defaultNoteLength}字 / {letters}封</div>
      } else {
        let letters = 0;

        if(newNoteLength <= 70) {
          letters = 1;
        } else if(newNoteLength >= 71 && newNoteLength <= 134) {
          letters = 2;
        } else if(newNoteLength >= 135 && newNoteLength <= 201) {
          letters = 3;
        } else if(newNoteLength >= 202 && newNoteLength <= 268) {
          letters = 4;
        } else if(newNoteLength >= 269 && newNoteLength <= 335) {
          letters = 5;
        };

        return <div className="countText">預估{newNoteLength}字 / {letters}封</div>
      }
    } else {
      let insertShortenUrlTimes = this.state.smsBookingDepositMsg.split(showUrl).length - 1;
      let insertDepositTime = this.state.smsBookingDepositMsg.split(showDepositTime).length - 1;
      let defaultNoteLength = this.state.defaultSmsBookingDepositMsg.length + (shortenUrlLength - showUrlLength) + (depositTimeLength - showDepositTimeLength);
      let newNoteLength = 
        this.state.smsBookingDepositMsg.length +
        insertShortenUrlTimes * (shortenUrlLength - showUrlLength) +
        insertDepositTime * (depositTimeLength - showDepositTimeLength);

      if(this.state.smsBookingDepositMsg.substring(this.state.smsBookingDepositMsg.length - showUrlLength) === showUrl) {
        newNoteLength --;
      };
      
      if(this.state.smsBookingDepositMsg.length === 0) {
        let letters = Math.ceil(defaultNoteLength / 75);
        return <div className="countText">預估{defaultNoteLength}字 / {letters}封</div>
      } else {
        let letters = 0;

        if(newNoteLength <= 70) {
          letters = 1;
        } else if(newNoteLength >= 71 && newNoteLength <= 134) {
          letters = 2;
        } else if(newNoteLength >= 135 && newNoteLength <= 201) {
          letters = 3;
        } else if(newNoteLength >= 202 && newNoteLength <= 268) {
          letters = 4;
        } else if(newNoteLength >= 269 && newNoteLength <= 335) {
          letters = 5;
        };

        return <div className="countText">預估{newNoteLength}字 / {letters}封</div>
      }
    }
  }

  renderSmsCreatedText = () => {
    if(this.state.sms_notification_settings.booking_created.offline || this.state.sms_notification_settings.booking_created.online) {
      return (
        <div className="smsTextBlock">
          <div className="smsTextTile">自訂文字備註內容（選填）</div>
          <button onClick={() => this.checkMsgLength(this.state.smsBookingCreatedMsg, 'shopName', 'created')}>嵌入店家名稱</button>
          <button onClick={() => this.checkMsgLength(this.state.smsBookingCreatedMsg, 'bookingTime', 'created')}>嵌入預約時間</button>
          <button onClick={() => this.checkMsgLength(this.state.smsBookingCreatedMsg, 'url', 'created')}>嵌入店家網址</button>
          <div className="smsTextareaBox smsTextareaBox_created">
            <textarea
              id="smsBookingNote_created"
              className="form-control smsTextarea"
              placeholder={this.state.defaultSmsBookingCreatedMsg}
              value={this.state.smsBookingCreatedMsg}
              onChange={e => {this.handleSmsMsg('created', e)}}
              onClick={() => this.handleCursorPosition('created')}
              onKeyUp={() => this.handleCursorPosition('created')}
            />
            { this.renderNumberOfCharacters('created') }
          </div>
        </div>
      )
    }
  }

  renderSmsReminderText = () => {
    if(this.state.sms_notification_settings.booking_reminder.offline || this.state.sms_notification_settings.booking_reminder.online) {
      return (
        <div className="smsTextBlock">
          <div className="smsTextTile">自訂文字備註內容（選填）</div>
          <button onClick={() => this.checkMsgLength(this.state.smsBookingReminderMsg, 'shopName', 'reminder')}>嵌入店家名稱</button>
          <button onClick={() => this.checkMsgLength(this.state.smsBookingReminderMsg, 'bookingTime', 'reminder')}>嵌入預約時間</button>
          <button onClick={() => this.checkMsgLength(this.state.smsBookingReminderMsg, 'url', 'reminder')}>嵌入店家網址</button>
          <div className="smsTextareaBox smsTextareaBox_reminder">
            <textarea
              id="smsBookingNote_reminder"
              className="form-control smsTextarea"
              placeholder={this.state.defaultSmsBookingReminderMsg}
              value={this.state.smsBookingReminderMsg}
              onChange={e => {this.handleSmsMsg('reminder', e)}}
              onClick={() => this.handleCursorPosition('reminder')}
              onKeyUp={() => this.handleCursorPosition('reminder')}
            />
            { this.renderNumberOfCharacters('reminder') }
          </div>
        </div>
      )
    }
  }

  renderSmsCanceledText = () => {
    if(this.state.sms_notification_settings.booking_canceled.offline || this.state.sms_notification_settings.booking_canceled.online) {
      return (
        <div className="smsTextBlock">
          <div className="smsTextTile">自訂文字備註內容（選填）</div>
          <button onClick={() => this.checkMsgLength(this.state.smsBookingCanceledMsg, 'shopName', 'canceled')}>嵌入店家名稱</button>
          <button onClick={() => this.checkMsgLength(this.state.smsBookingCanceledMsg, 'bookingTime', 'canceled')}>嵌入預約時間</button>
          <button onClick={() => this.checkMsgLength(this.state.smsBookingCanceledMsg, 'url', 'canceled')}>嵌入店家網址</button>
          <div className="smsTextareaBox smsTextareaBox_canceled">
            <textarea
              id="smsBookingNote_canceled"
              className="form-control smsTextarea"
              placeholder={this.state.defaultSmsBookingCanceledMsg}
              value={this.state.smsBookingCanceledMsg}
              onChange={e => {this.handleSmsMsg('canceled', e)}}
              onClick={() => this.handleCursorPosition('canceled')}
              onKeyUp={() => this.handleCursorPosition('canceled')}
            />
            { this.renderNumberOfCharacters('canceled') }
          </div>
        </div>
      )
    }
  }

  renderSmsDepositText = () => {
    if(this.state.sms_notification_settings.booking_deposit.offline || this.state.sms_notification_settings.booking_deposit.online) {
      return (
        <div className="smsTextBlock">
          <div className="smsTextTile">自訂文字備註內容（選填）</div>
          <button onClick={() => this.checkMsgLength(this.state.smsBookingDepositMsg, 'shopName', 'deposit')}>嵌入店家名稱</button>
          <button onClick={() => this.checkMsgLength(this.state.smsBookingDepositMsg, 'bookingTime', 'deposit')}>嵌入付款期限</button>
          <button onClick={() => this.checkMsgLength(this.state.smsBookingDepositMsg, 'url', 'deposit')}>嵌入店家網址</button>
          <div className="smsTextareaBox smsTextareaBox_deposit">
            <textarea
              id="smsBookingNote_deposit"
              className="form-control smsTextarea"
              placeholder={this.state.defaultSmsBookingDepositMsg}
              value={this.state.smsBookingDepositMsg}
              onChange={e => {this.handleSmsMsg('deposit', e)}}
              onClick={() => this.handleCursorPosition('deposit')}
              onKeyUp={() => this.handleCursorPosition('deposit')}
            />
            { this.renderNumberOfCharacters('deposit') }
          </div>
          { this.renderSmsDepositNotice() }
        </div>
      )
    }
  }

  renderMsgBlockHeaderTags = (
    isEmailActive,
    isLineActive,
    isSmsOfflineActive,
    isSmsOnlineActive,
    isDepositMode = false
  ) => {
    let smsTagStyle, emailTagStyle, lineTagStyle;

    if(
      (this.state.disableMsg && (isSmsOfflineActive || isSmsOnlineActive)) || // 點數不足
      ( // 訂金資訊通知: 未開啟線上付款功能但卻開啟簡訊通知
        (
          isDepositMode && !this.state.deposit_required &&
          (isSmsOfflineActive || isSmsOnlineActive)
        ) ||
        (
          isDepositMode && (isSmsOfflineActive || isSmsOnlineActive) &&
          (this.state.spg_merchant_id === '' || this.state.spg_merchant_id === null ||
          this.state.spg_hash_key === '' || this.state.spg_hash_key === null ||
          this.state.spg_hash_iv === '' || this.state.spg_hash_iv === null ||
          !this.state.merchant_verified)
        )
      )
    ) {
      smsTagStyle = 'disabled';
    } else {
      if(isSmsOfflineActive || isSmsOnlineActive) smsTagStyle = 'active';
    }

    if( // 有開啟線上付款功能
      isDepositMode && this.state.deposit_required &&
      (this.state.spg_merchant_id !== '' && this.state.spg_merchant_id !== null &&
      this.state.spg_hash_key !== '' && this.state.spg_hash_key !== null &&
      this.state.spg_hash_iv !== '' && this.state.spg_hash_iv !== null &&
      this.state.merchant_verified)
    ) {
      emailTagStyle = 'active';
      lineTagStyle = 'active'
    } else {
      if(isEmailActive) emailTagStyle  = 'active';
      if(isLineActive) lineTagStyle  = 'active';
    }

    return (
      <div className='msg_block_header_tags'>
        <div className={emailTagStyle}>E-mail</div>
        <div className={lineTagStyle}>LINE</div>
        <div className={smsTagStyle}>簡訊</div>
      </div>
    )
  };

  changeMsgBlockTab = (bookingType, option) => {
    if(bookingType === 'bookingCreatedTab') this.setState({ bookingCreatedTab: option });
    if(bookingType === 'bookingReminderTab') this.setState({ bookingReminderTab: option });
    if(bookingType === 'bookingCanceledTab') this.setState({ bookingCanceledTab: option });
  };

  renderMsgBlockTab = (bookingTypeTab, bookingType) => {
    return (
      <div className="msg_block_type_tab">
        {Object.keys(msgTypeTab).map((option, index) => {
					return (
            <div
              key={index}
              className={option === bookingTypeTab ? 'active' : ''}
              onClick={() => this.changeMsgBlockTab(bookingType, option)}
            >
              {msgTypeTab[option]}
            </div>
          );
				})}
      </div>
    )
  };

  renderBookingCreatedMsg = () => {
    if(this.state.bookingCreatedTab === 'email') {
      return (
        <Fragment>
          <div className='checkBlock'>
            <div>開啟通知</div>
            <label className="msgLabel">
              <input
                type="checkbox"
                checked={this.state.email_notification_settings.booking_created.enabled}
                onChange={() => { this.handleEmailNotificationEnabled('booking_created') }}
              />
              <span>勾選開啟</span>
            </label>
          </div>
          { this.state.email_notification_settings.booking_created.enabled ? this.renderEmailCreatedText() : null}
        </Fragment>
      )
    } 
    
    if(this.state.bookingCreatedTab === 'line') {
      return (
        <Fragment>
          <div className='checkBlock'>
            <div>開啟通知</div>
            <label className="msgLabel">
              <input
                type="checkbox"
                checked={this.state.line_notify_settings.booking_created.enabled}
                onChange={() => { this.handleLineNotificationEnabled('booking_created') }}
              />
              <span>勾選開啟</span>
            </label>
          </div>
          { this.state.line_notify_settings.booking_created.enabled ? this.renderLineCreatedText() : null}
        </Fragment>
      )
    }

    if(this.state.bookingCreatedTab === 'sms') {
      return (
        <Fragment>
          <div className="checkBlock">
            <div>開啟通知</div>
            <label className="msgLabel">
              <input
                type="checkbox"
                checked={this.state.sms_notification_settings.booking_created.offline}
                onChange={() => this.handleSmsNotificationEnabled('booking_created', 'offline')}
              />
              <span>自建預約</span>
            </label>
            <label className="msgLabel">
              <input
                type="checkbox"
                checked={this.state.sms_notification_settings.booking_created.online}
                onChange={() => this.handleSmsNotificationEnabled('booking_created', 'online')}
              />
              <span>線上預約</span>
            </label>
          </div>
          { this.state.disableMsg && (this.state.sms_notification_settings.booking_created.offline || this.state.sms_notification_settings.booking_created.online) ? <div className='msg_block_txt_error'>簡訊點數餘額不足</div> : null }
          { this.renderSmsCreatedText() }
        </Fragment>
      )
    }
  };

  renderBookingReminderMsg = () => {
    if(this.state.bookingReminderTab === 'email') {
      return (
        <Fragment>
          <div className='checkBlock'>
            <div>開啟通知</div>
            <label className="msgLabel">
              <input
                type="checkbox"
                checked={this.state.email_notification_settings.booking_reminder.enabled}
                onChange={() => { this.handleEmailNotificationEnabled('booking_reminder') }}
              />
              <span>勾選開啟</span>
            </label>
          </div>
          { this.state.email_notification_settings.booking_reminder.enabled ? this.renderEmailReminderText() : null}
        </Fragment>
      )
    } 
    
    if(this.state.bookingReminderTab === 'line') {
      return (
        <Fragment>
          <div className='checkBlock'>
            <div>開啟通知</div>
            <label className="msgLabel">
              <input
                type="checkbox"
                checked={this.state.line_notify_settings.booking_reminder.enabled}
                onChange={() => { this.handleLineNotificationEnabled('booking_reminder') }}
              />
              <span>勾選開啟</span>
            </label>
          </div>
          { this.state.line_notify_settings.booking_reminder.enabled ? this.renderLineReminderText() : null}
        </Fragment>
      )
    }

    if(this.state.bookingReminderTab === 'sms') {
      return (
        <Fragment>
          <div className="checkBlock">
            <div>開啟通知</div>
            <label className="msgLabel">
              <input
                type="checkbox"
                checked={this.state.sms_notification_settings.booking_reminder.offline}
                onChange={() => this.handleSmsNotificationEnabled('booking_reminder', 'offline')}
              />
              <span>自建預約</span>
            </label>
            <label className="msgLabel">
              <input
                type="checkbox"
                checked={this.state.sms_notification_settings.booking_reminder.online}
                onChange={() => this.handleSmsNotificationEnabled('booking_reminder', 'online')}
              />
              <span>線上預約</span>
            </label>
          </div>
          { this.state.disableMsg && (this.state.sms_notification_settings.booking_reminder.offline || this.state.sms_notification_settings.booking_reminder.online) ? <div className='msg_block_txt_error'>簡訊點數餘額不足</div> : null }
          { this.renderSmsReminderText() }
        </Fragment>
      )
    }
  };

  renderBookingCanceledMsg = () => {
    if(this.state.bookingCanceledTab === 'email') {
      return (
        <Fragment>
          <div className='checkBlock'>
            <div>開啟通知</div>
            <label className="msgLabel">
              <input
                type="checkbox"
                checked={this.state.email_notification_settings.booking_canceled.enabled}
                onChange={() => { this.handleEmailNotificationEnabled('booking_canceled') }}
              />
              <span>勾選開啟</span>
            </label>
          </div>
          { this.state.email_notification_settings.booking_canceled.enabled ? this.renderEmailCanceledText() : null}
        </Fragment>
      )
    } 
    
    if(this.state.bookingCanceledTab === 'line') {
      return (
        <Fragment>
          <div className='checkBlock'>
            <div>開啟通知</div>
            <label className="msgLabel">
              <input
                type="checkbox"
                checked={this.state.line_notify_settings.booking_canceled.enabled}
                onChange={() => { this.handleLineNotificationEnabled('booking_canceled') }}
              />
              <span>勾選開啟</span>
            </label>
          </div>
          { this.state.line_notify_settings.booking_canceled.enabled ? this.renderLineCanceledText() : null}
        </Fragment>
      )
    }

    if(this.state.bookingCanceledTab === 'sms') {
      return (
        <Fragment>
          <div className="checkBlock">
            <div>開啟通知</div>
            <label className="msgLabel">
              <input
                type="checkbox"
                checked={this.state.sms_notification_settings.booking_canceled.offline}
                onChange={() => this.handleSmsNotificationEnabled('booking_canceled', 'offline')}
              />
              <span>自建預約</span>
            </label>
            <label className="msgLabel">
              <input
                type="checkbox"
                checked={this.state.sms_notification_settings.booking_canceled.online}
                onChange={() => this.handleSmsNotificationEnabled('booking_canceled', 'online')}
              />
              <span>線上預約</span>
            </label>
          </div>
          { this.state.disableMsg && (this.state.sms_notification_settings.booking_canceled.offline || this.state.sms_notification_settings.booking_canceled.online) ? <div className='msg_block_txt_error'>簡訊點數餘額不足</div> : null }
          { this.renderSmsCanceledText() }
        </Fragment>
      )
    }
  };

  renderSmsDepositError = () => {
    if(this.state.sms_notification_settings.booking_deposit.offline || // 訂金資訊開啟簡訊通知
      this.state.sms_notification_settings.booking_deposit.online) {
        if(
          this.state.spg_merchant_id === '' || this.state.spg_merchant_id === null ||
          this.state.spg_hash_key === '' || this.state.spg_hash_key === null ||
          this.state.spg_hash_iv === '' || this.state.spg_hash_iv === null ||
          !this.state.merchant_verified || !this.state.deposit_required
        ) {
          return <div className='msg_block_txt_error'>尚未開啟線上付款功能</div>;
        } else if(this.state.disableMsg) {
          return <div className='msg_block_txt_error'>簡訊點數餘額不足</div>;
        }
      }
  };

  renderSmsDepositNotice = () => {
    const smsBookingDepositMsg = this.state.smsBookingDepositMsg;
    const showUrl = this.state.showUrl;

    if(!smsBookingDepositMsg.includes(showUrl)) {
      return <div className='msg_block_txt_error notice'>提醒：訊息內容若未嵌入店家網址，顧客恐遺漏付款網址</div>;
    }
  };

  renderCustomPointsInput = () => {
		const handleKeyPress = (e) => {
			// 只能輸入數字
			const pattern = /^[0-9\b]+$/;
			const inputChar = String.fromCharCode(e.charCode);
	
			if (!pattern.test(inputChar)) {
				e.preventDefault();
			}
		};

		return (
			<div className={ this.state.showCustomPointsError ? 'custom_input-error' : '' }>
				<input
					type="number"
					value={this.state.customPoints || ''}
					placeholder='100'
					onChange={(e) => this.buyMsgPoint(e, true)}
					onKeyPress={handleKeyPress}
				/>
				{ this.state.showCustomPointsError && <div>單次儲值至少需100點</div> }
			</div>
		)
	};

  renderLineNotifyButton = () => {
    if(this.state.line_notify_shop_token_present) {
      return (
        <div className='block_line_open'>
          <div>您已開啟 LINE Notify 通知</div>
          <div className='block_line_open_btn'>
            <button onClick={() => this.setState({ showLineNotifyRebindModal: true })}>重新綁定</button>
            <button onClick={() => this.setState({ showLineNotifyUnbindModal: true })}>關閉通知</button>
          </div>
        </div>
      )
    } else {
      return (
        <button onClick={this.bindLineNotify}>開啟 LINE 通知提醒</button>
      )
    }
  };

  closeLineNotifyModal = () => {
    this.setState({ showLineNotifyRebindModal: false, showLineNotifyUnbindModal: false });
  };

  bindLineNotify = () => {
    $.ajax({url: window.domain + '/dashboard/line_notify', 
        data: {
          from_shop: this.state.shop.id
        },
        method: 'POST',
        xhrFields: { withCredentials: true }})
      .done((data) => {
        window.location.href = data.url;
        this.setState({
          showLineNotifyRebindModal: false
        });
      })
      .fail(function(xhr){
        window.app.alert.setMessage("請稍後再試", "error");        
        handleError(xhr);
      });
  };

  unbindLineNotify = () => {
    $.ajax({url: window.domain + '/dashboard/line_notify_cancel', 
      data: {
        from_shop: this.state.shop.id
      },
      method: 'POST',
      xhrFields: { withCredentials: true }})
    .done((data) => {
      window.app.alert.setMessage("已關閉 LINE Notify 通知", "done");
      this.setState({
        showLineNotifyUnbindModal: false
      });
      this.updateLineNotifyBindStatus();
    })
    .fail(function(xhr){
      window.app.alert.setMessage("請稍後再試", "error");
      handleError(xhr);
    });
  }

  updateLineNotifyBindStatus = () => {
    $.ajax({url: window.domain + '/dashboard/booking_settings', 
        method: 'GET', 
        xhrFields: { withCredentials: true }})
    .done(function(data){
      this.setState({ line_notify_shop_token_present: data.line_notify_shop_token_present })
    }.bind(this))
    .fail(function(xhr){
      handleError(xhr);
    });
  }

  updateSmsNote = () => {
    $.ajax({url: window.domain + '/dashboard/booking_settings', 
        method: 'GET', 
        xhrFields: { withCredentials: true }})
    .done(function(data){
      const showUrl = this.state.showUrl;
      const showTime = this.state.showTime;
      const showDepositTime = this.state.showDepositTime;
      const regShortenUrl = /MNUSHOP_SHORTEN_URL /g;
      const regDefaultShortenUrl = /MNUSHOP_SHORTEN_URL/g;
      const regBookingTime = /MNUSHOP_BOOKING_DATETIME/g;
      const regDepositTime = /DEPOSIT_DUE_DATE/g;

      // 更新簡訊文案
      this.setState({
        smsBookingCreatedMsg: data.sms_notification_settings.booking_created.msg.replace(regShortenUrl, showUrl).replace(regDefaultShortenUrl, showUrl).replace(regBookingTime, showTime),
        smsBookingReminderMsg: data.sms_notification_settings.booking_reminder.msg.replace(regShortenUrl, showUrl).replace(regDefaultShortenUrl, showUrl).replace(regBookingTime, showTime),
        smsBookingCanceledMsg: data.sms_notification_settings.booking_canceled.msg.replace(regShortenUrl, showUrl).replace(regDefaultShortenUrl, showUrl).replace(regBookingTime, showTime),
        smsBookingDepositMsg: data.sms_notification_settings.booking_deposit.msg.replace(regShortenUrl, showUrl).replace(regDefaultShortenUrl, showUrl).replace(regDepositTime, showDepositTime),
      })
    }.bind(this))
    .fail(function(xhr){
      handleError(xhr);
    });
  }

  submit = () =>{
    const shortenUrl = this.state.shortenUrl;
    const showUrl = this.state.showUrl;
    const bookingTime = this.state.bookingTime;
    const showTime = this.state.showTime;
    const depositTime = this.state.depositTime;
    const showDepositTime = this.state.showDepositTime;

    const regShowUrl = /\*\/店家網址\/\*/g;
    const regShowTime = /\*\/預約時間\/\*/g;
    const regShowDepositTime = /\*\/付款期限\/\*/g;

    const smsBookingCreatedMsg =
      this.state.smsBookingCreatedMsg.trim().replace(regShowUrl, shortenUrl).replace(regShowTime, bookingTime);
    const smsBookingReminderMsg =
      this.state.smsBookingReminderMsg.trim().replace(regShowUrl, shortenUrl).replace(regShowTime, bookingTime);
    const smsBookingCanceledMsg =
      this.state.smsBookingCanceledMsg.trim().replace(regShowUrl, shortenUrl).replace(regShowTime, bookingTime);
    const smsBookingDepositMsg = 
      this.state.smsBookingDepositMsg.trim().replace(regShowUrl, shortenUrl).replace(regShowDepositTime, depositTime);

    const defaultSmsBookingCreatedMsg =
      this.state.defaultSmsBookingCreatedMsg.replace(showUrl, shortenUrl).replace(showTime, bookingTime);
    const defaultSmsBookingReminderMsg =
      this.state.defaultSmsBookingReminderMsg.replace(showUrl, shortenUrl).replace(showTime, bookingTime);
    const defaultSmsBookingCanceledMsg =
      this.state.defaultSmsBookingCanceledMsg.replace(showUrl, shortenUrl).replace(showTime, bookingTime);
    const defaultSmsBookingDepositMsg =
      this.state.defaultSmsBookingDepositMsg.replace(showUrl, shortenUrl).replace(showDepositTime, depositTime);

    let newSmsBookingCreatedMsg = null;
    let newSmsBookingReminderMsg = null;
    let newSmsBookingCanceledMsg = null;
    let newSmsBookingDepositMsg = null;

    let { sms_enable_condition, email_notification_settings, line_notify_settings } = this.state;

    // 檢查文案是否為空，空的話則填入預設值，否則更新內容
    if(smsBookingCreatedMsg !== '') {
      newSmsBookingCreatedMsg = true
    } else {
      newSmsBookingCreatedMsg = false
    };

    if(smsBookingReminderMsg !== '') {
      newSmsBookingReminderMsg = true;
    } else {
      newSmsBookingReminderMsg = false;
    };

    if(smsBookingCanceledMsg !== '') {
      newSmsBookingCanceledMsg = true;
    } else {
      newSmsBookingCanceledMsg = false;
    };

    if(smsBookingDepositMsg !== '') {
      newSmsBookingDepositMsg = true;
    } else {
      newSmsBookingDepositMsg = false;
    };

    const smsNotificationSettings = JSON.stringify({
      booking_deposit: {
        online: this.state.sms_notification_settings.booking_deposit.online,
        offline: this.state.sms_notification_settings.booking_deposit.offline,
        msg: newSmsBookingDepositMsg ? smsBookingDepositMsg : defaultSmsBookingDepositMsg
      },
      booking_created: {
        online: this.state.sms_notification_settings.booking_created.online,
        offline: this.state.sms_notification_settings.booking_created.offline,
        msg: newSmsBookingCreatedMsg ? smsBookingCreatedMsg : defaultSmsBookingCreatedMsg
      },
      booking_reminder: {
        online: this.state.sms_notification_settings.booking_reminder.online,
        offline: this.state.sms_notification_settings.booking_reminder.offline,
        msg: newSmsBookingReminderMsg ? smsBookingReminderMsg : defaultSmsBookingReminderMsg
      },
      booking_canceled: {
        online: this.state.sms_notification_settings.booking_canceled.online,
        offline: this.state.sms_notification_settings.booking_canceled.offline,
        msg: newSmsBookingCanceledMsg ? smsBookingCanceledMsg : defaultSmsBookingCanceledMsg
      }
    })
    
    this.setState({sending: true})
    $.ajax({url: window.domain + '/dashboard/booking_settings', 
        data: {booking_settings:{
          sms_enable_condition: JSON.stringify(sms_enable_condition),
          booking_notification_email: this.state.booking_notification_email,
          sms_notification_settings: smsNotificationSettings,
          email_notification_settings: JSON.stringify(email_notification_settings),
          line_notify_settings: JSON.stringify(line_notify_settings),
        }},
        method: 'PATCH', 
        xhrFields: { withCredentials: true }})
      .done((data) => {
        this.setState({isSaved: true, sending: false})
        this.updateSmsNote();
        window.app.alert.setMessage("儲存成功", "done");
      })
      .fail(function(xhr){
        this.setState({sending: false})
        window.app.alert.setMessage("請稍後再試", "error");        
        handleError(xhr);
      });
  }

  render() {
    if(this.state.init){
      return (
        <>
          <div className="main overflow bookingSettingMsg">
            {this.state.sending && (
              <div className='lightBoxLayer'>
                <h4>儲存中</h4>
              </div>
            )}

            <div style={{ marginBottom: '32px' }}>
              <h3>預約通知設定</h3>
              <h5>您可以設定在不同預約情境中，是否開啟各類型的通知功能，以及設定通知的文案內容。</h5>
            </div>
            
            {/* 預約成功通知 */}
            <div className='msg_block'>
              <div className='msg_block_header'>
                <h4>預約成功通知</h4>
                { 
                  this.renderMsgBlockHeaderTags(
                    this.state.email_notification_settings.booking_created.enabled,
                    this.state.line_notify_settings.booking_created.enabled,
                    this.state.sms_notification_settings.booking_created.offline,
                    this.state.sms_notification_settings.booking_created.online
                  )
                }
              </div>
              <div className='msg_block_txt'>開啟時，當預約建立成功時將發送訊息通知顧客</div>
              { this.renderMsgBlockTab(this.state.bookingCreatedTab, 'bookingCreatedTab') }
              { this.renderBookingCreatedMsg() }
            </div>

            {/* 預約提醒通知 */}
            <div className='msg_block' style={{ marginTop: '42px' }}>
              <div className='msg_block_header'>
                <h4>預約提醒通知</h4>
                { 
                  this.renderMsgBlockHeaderTags(
                    this.state.email_notification_settings.booking_reminder.enabled,
                    this.state.line_notify_settings.booking_reminder.enabled,
                    this.state.sms_notification_settings.booking_reminder.offline,
                    this.state.sms_notification_settings.booking_reminder.online
                  )
                }
              </div>
              <div className='msg_block_txt'>開啟時，將在顧客預約日前一天的中午12:00發送訊息通知顧客</div>
              { this.renderMsgBlockTab(this.state.bookingReminderTab, 'bookingReminderTab') }
              { this.renderBookingReminderMsg() }
            </div>

            {/* 預約取消通知 */}
            <div className='msg_block' style={{ marginTop: '42px' }}>
              <div className='msg_block_header'>
                <h4>預約取消通知</h4>
                { 
                  this.renderMsgBlockHeaderTags(
                    this.state.email_notification_settings.booking_canceled.enabled,
                    this.state.line_notify_settings.booking_canceled.enabled,
                    this.state.sms_notification_settings.booking_canceled.offline,
                    this.state.sms_notification_settings.booking_canceled.online
                  )
                }
              </div>
              <div className='msg_block_txt'>開啟時，將在取消預約時發送訊息通知顧客</div>
              { this.renderMsgBlockTab(this.state.bookingCanceledTab, 'bookingCanceledTab') }
              { this.renderBookingCanceledMsg() }
            </div>

            {/* 訂金資訊通知 */}
            <div className='msg_block' style={{ marginTop: '42px' }}>
              <div className='msg_block_header'>
                <h4>訂金資訊通知</h4>
                { 
                  this.renderMsgBlockHeaderTags(
                    '',
                    '',
                    this.state.sms_notification_settings.booking_deposit.offline,
                    this.state.sms_notification_settings.booking_deposit.online,
                    true
                  )
                }
              </div>
              <div className='msg_block_txt'>若開啟預付訂金功能，系統將自動以 E-mail 及 LINE 發送訂金資訊，不過您可自訂是否以簡訊發送訂金資訊給顧客</div>
              <div className="msg_block_type_tab msg_block_sms_tab">
                <div className='active'>簡訊</div>
              </div>
              <div className="checkBlock">
                <div>開啟通知</div>
                <label className="msgLabel">
                  <input
                    type="checkbox"
                    checked={this.state.sms_notification_settings.booking_deposit.offline}
                    onChange={() => this.handleSmsNotificationEnabled('booking_deposit', 'offline')}
                  />
                  <span>自建預約</span>
                </label>
                <label className="msgLabel">
                  <input
                    type="checkbox"
                    checked={this.state.sms_notification_settings.booking_deposit.online}
                    onChange={() => this.handleSmsNotificationEnabled('booking_deposit', 'online')}
                  />
                  <span>線上預約</span>
                </label>
              </div>
              { this.renderSmsDepositError() }
              { this.renderSmsDepositText() }
            </div>

            <hr />

            <div className="msgPointBlock">
              <div className="block_title">
                <h3>簡訊點數</h3>
              </div>
              <div className="rowBlock">
                <div className="rowBlock_text">目前點數餘額</div>
                <div className="rowBlock_text">{this.state.shop.msg_point}</div>
              </div>
              <div className="intro_text" style={{ marginBottom: '24px' }}>當簡訊數量歸零即自動關閉簡訊服務</div>
              <div className="subtitle">購買點數</div>
              <div className='intro_text'>單次儲值至少需100點；儲值需額外支付10%營業稅與交易手續費</div>
              <div className='radioBox'>
                <label>
                  <input
                    type="radio"
                    name='selectPoints'
                    value={100}
                    checked={this.state.selectedPointsOption === 100}
                    onChange={(e) => this.buyMsgPoint(e)}
                  />
                  100
                </label>
                <label>
                  <input
                    type="radio"
                    name='selectPoints'
                    value={200}
                    checked={this.state.selectedPointsOption === 200}
                    onChange={(e) => this.buyMsgPoint(e)}
                  />
                  200
                </label>
                <label>
                  <input
                    type="radio"
                    name='selectPoints'
                    value={300}
                    checked={this.state.selectedPointsOption === 300}
                    onChange={(e) => this.buyMsgPoint(e)}
                  />
                  300
                </label>
                <label>
                  <input
                    type="radio"
                    name='selectPoints'
                    value={400}
                    checked={this.state.selectedPointsOption === 400}
                    onChange={(e) => this.buyMsgPoint(e)}
                  />
                  400
                </label>
                <div className='custom_input'>
                  <label>
                    <input
                      type="radio"
                      name='selectPoints'
                      value={'custom'}
                      checked={this.state.selectedPointsOption === 'custom'}
                      onChange={(e) => this.buyMsgPoint(e)}
                    />
                    自訂購買點數
                  </label>
                  {
                    this.state.selectedPointsOption === 'custom' ? this.renderCustomPointsInput() : null
                  }
                </div>
              </div>
              <button
                type="button"
                onClick={this.submitBuyMsgPoint}
                className="btn btn-default btn-lg msg_btn"
              >
                購買簡訊點數
              </button>
            </div>

            <hr />

            <div className="bookingReportBlock">
              <div className="block_title">
                <h3>預約回報</h3>
                <h5>顧客預約或取消預約時，發送通知提醒店家</h5>
              </div>

              <div className='block_line_notify'>
                <div className="block_subtile">LINE Notify 通知</div>
                <h5>可利用LINE建立店家內部群組，系統將能透過LINE Notify發送通知</h5>
                {this.renderLineNotifyButton()}
                <div className='block_note'>
                  <div>*此功能僅能綁定LINE個人帳號，無法綁定LINE官方帳號</div>
                  <div>*需將「LINE Notify」官方帳號加入好友</div>
                </div>
              </div>

              <div className='block_email'>
                <div className="block_subtile">回報 E-mail</div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    ref="email"
                    className="form-control input-lg msgInput"
                    name="booking_notification_email"
                    placeholder="請輸入回報 E-mail"
                  />
                  <button
                    type="button"
                    onClick={this.addEmail}
                    className="btn btn-default btn-lg msg_btn"
                  >
                    新增
                  </button>
                </div>
                <div className="email_lists">
                  {
                    this.state.booking_notification_email &&
                    this.state.booking_notification_email !== null &&
                    _.compact(this.state.booking_notification_email.split(';')).map((email, idx) => {
                    return (
                      <div key={email} className="block_text"> {email}
                        <a><span onClick={() => this.removeEmail(idx)} style={{paddingLeft: '5px'}} className="glyphicon glyphicon-remove-circle btn-delete" /></a>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div> 
            
            <br/><br/>
            <form action={config.allpay_checkout} id="apForm" method="post" target="_blank">
            </form>

            { this.state.showLineNotifyRebindModal &&
              <LineNotifyRebindModal
                cancel={this.closeLineNotifyModal}
                bindLineNotify={this.bindLineNotify}
              />
            }

            { this.state.showLineNotifyUnbindModal &&
              <LineNotifyUnbindModal
                cancel={this.closeLineNotifyModal}
                unbindLineNotify={this.unbindLineNotify}
              />
            }
          </div>

          <hr className='main_width' />

          <div className='fix_bottom'>
            <button
              className='btn_submit'
              onClick={this.submit}
            >
              儲存
            </button>
          </div>
        </>
      );
    }else {
      return <div className="pageLoading">讀取中..</div>;
    }
  }
}