import React, { useState } from "react";
import { Space } from "antd";
import moment from "moment";

import verifiedIcon from "../../images/icon_verified.svg";
import syncIcon from "../../images/sync-icon.svg";
import unsyncIcon from "../../images/unsync-icon.svg";

import { bookingRetryDuDooSync } from "../../api/posSync";

import "../../sass/dudooPosSync.scss";

const DudooSyncDisplay = ({ mode, booking, updateData }) => {
  const [isRetrying, setIsRetrying] = useState(false);
  const spaceSize = mode === "list" ? 8 : 4;

  const SYNC_MESSAGES = {
    FAIL: "無法建立Dudoo預約.",
    SEATS_MISMATCH: "座位設定與肚肚不一致",
    SUCCESS_TITLE: "預約資料同步成功",
    ERROR_TITLE: "預約資料同步失敗",
  };

  const isSyncFailed = (message) =>
    [SYNC_MESSAGES.FAIL, SYNC_MESSAGES.SEATS_MISMATCH].includes(message);

  const handleRetry = async () => {
    setIsRetrying(true);

    try {
      const { data } = await bookingRetryDuDooSync(booking.id);
      const isSuccess = !isSyncFailed(data.message);

      if (isSuccess) {
        updateData();
      }

      window.app.alert.setMessage(
        isSuccess ? SYNC_MESSAGES.SUCCESS_TITLE : SYNC_MESSAGES.ERROR_TITLE,
        isSuccess ? "done" : "error"
      );
    } catch (error) {
      window.app.alert.setMessage(SYNC_MESSAGES.ERROR_TITLE, "error");
    } finally {
      setIsRetrying(false);
    }
  };

  const renderDudooSyncTime = () => {
    const syncTime = booking?.pos_sync_info?.time
      ? moment(booking.pos_sync_info.time).format("YYYY/MM/DD HH:mm")
      : null;

    if (!booking.pos_sync_failed) {
      return (
        <Space size={spaceSize} className="syncTime success_text">
          與肚肚同步: {syncTime}
          <img src={verifiedIcon} alt="" />
        </Space>
      );
    } else {
      return (
        <Space size={spaceSize}>
          <div>
            <div className="syncTime error_text">未與肚肚同步: {syncTime}</div>
          </div>
          {booking.status !== "cancel" && (
            <button
              className="button-dudoo-retry"
              onClick={handleRetry}
              disabled={isRetrying}
            >
              <div className={`icon-retry ${isRetrying ? "loading" : ""}`} />
            </button>
          )}
        </Space>
      );
    }
  };

  if (!booking.pos_sync_required) return null;
  return <div className="dudooSyncBlock">{renderDudooSyncTime()}</div>;
};

const DudooSyncStatus = ({ isDudooSync = false }) => {
  return (
    <div
      className={`dudooSyncStatus ${
        isDudooSync ? "dudooSyncStatus-success" : "dudooSyncStatus-fail"
      }`}
    >
      <Space size={4}>
        <img src={isDudooSync ? syncIcon : unsyncIcon} alt="" />
        {isDudooSync ? "肚肚POS同步中" : "座位設定與肚肚POS不一致"}
      </Space>
    </div>
  );
};

export { DudooSyncDisplay, DudooSyncStatus };
