import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";

import BookingSystemApi from "../booking_settings/BookingSystemApi";
import { checkDudooTableSync } from "../../api/posSync";

import verifiedIcon from "../../images/icon_verified.svg";
import loadingIcon from "../../images/btn-loading.svg";
import ConfirmPopup from "../popup/confirmPopup";
import { Space } from "antd";

import "../../sass/dudooPosSync.scss";

const API = new BookingSystemApi();

function DudooPosSync() {
  const auth = useSelector((state) => state.auth);
  const { shop } = auth;
  const { pos_enabled } = shop;

  const [init, setInit] = useState(false);
  const [sending, setSending] = useState(false);
  const [dudooAppId, setDudooAppId] = useState("");
  const [canvasEnabled, setCanvasEnabled] = useState(false); // 座位圖開關
  const [fetchDudooSyncEnabled, setFetchDudooSyncEnabled] = useState(false); // 儲存後的狀態
  const [dudooSyncEnabled, setDudooSyncEnabled] = useState(false); // 編輯時的狀態
  const [isSyncSuccessful, setIsSyncSuccessful] = useState(false);
  const [isCheckSuccessful, setIsCheckSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const getBookingSetting = async () => {
    try {
      const data = await API.getBookingSetting();
      const {
        pos_enabled,
        pos_settings_matched,
        canvas_enabled,
        dudoo_app_id,
      } = data;

      setInit(true);
      setDudooAppId(dudoo_app_id);
      setFetchDudooSyncEnabled(pos_enabled);
      setDudooSyncEnabled(pos_enabled);
      setIsSyncSuccessful(pos_settings_matched);
      setCanvasEnabled(canvas_enabled);
    } catch (err) {
      console.log("dudoo getBookingSetting err---", err);
      window.app.alert.setMessage("請稍後再試", "error");
    }
  };

  useEffect(() => {
    getBookingSetting();
  }, []);

  const onChange = () => {
    setDudooSyncEnabled((prev) => !prev);
  };

  const handleRetry = useCallback(async () => {
    try {
      setIsLoading(true);

      if (!canvasEnabled) {
        throw new Error("Canvas mode is disabled, skipping check");
      }

      await checkDudooTableSync();
      setIsCheckSuccessful(true);
      setIsSyncSuccessful(true);
      window.app.alert.setMessage("座位對應檢查一致", "done");
    } catch (error) {
      setIsSyncSuccessful(false);
      window.app.alert.setMessage("座位對應檢查不一致", "error");
    } finally {
      setIsLoading(false);
    }
  }, [canvasEnabled]);

  const submit = async () => {
    try {
      setSending(true);

      if (!canvasEnabled && dudooSyncEnabled && !fetchDudooSyncEnabled) return setShowConfirmModal(true);
      if (dudooSyncEnabled) {
        try {
          await checkDudooTableSync();
          setIsSyncSuccessful(true);
        } catch (error) {
          setShowConfirmModal(true);
          setIsSyncSuccessful(false);

          console.error("Sync check error", error);
          window.app.alert.setMessage("請稍後再試", "error");
          return;
        }
      }

      const posEnabled = JSON.stringify({
        booking_settings: {
          pos_enabled: dudooSyncEnabled,
        },
      });

      await API.updateBookingSetting(posEnabled);
      setFetchDudooSyncEnabled(dudooSyncEnabled);
      window.app.alert.setMessage("儲存成功", "done");
    } catch (error) {
      console.error("Dudoo settings update error", error);
      window.app.alert.setMessage("請稍後再試", "error");
    } finally {
      setSending(false);
    }
  };

  const renderSyncButton = () => {
    return (
      <div className="mb-32">
        <div className="row_subtitle-16 mb-4">座位對應檢查</div>
        <div className="additional_text">
          MENU店+與肚肚POS系統的座位設定若不一致，可能導致部分預約資料無法同步
        </div>
        <div className="syncButton-block">
          <div
            className={`syncText ${
              isSyncSuccessful ? "success_text" : "error_text"
            }`}
          >
            {isSyncSuccessful
              ? "最近變更過座位或座位圖嗎？"
              : "檢查到座位設定不一致，請手動調整並重新檢查。"}
          </div>
          {isSyncSuccessful ? (
            isCheckSuccessful ? (
              <button className="btn-sync-success">
                <Space size={4}>
                  <img src={verifiedIcon} alt="" />
                  檢查一致
                </Space>
              </button>
            ) : (
              <button onClick={handleRetry}>
                {isLoading ? <img src={loadingIcon} alt="" /> : "立即檢查"}
              </button>
            )
          ) : (
            <button onClick={handleRetry}>重新檢查</button>
          )}
        </div>
      </div>
    );
  };

  const renderEnabled = () => {
    if (pos_enabled) {
      return (
        <>
          <div className="settingTbl">
            <div className="settingTblRow fix-settingTblRow">
              <div className="header fix-header">啟用狀態</div>
              <div className="text fix-text">
                <label className="msSwitch">
                  <input
                    type="checkbox"
                    name="enable"
                    onChange={onChange}
                    checked={dudooSyncEnabled}
                    value={true}
                  />
                  <span className="slider" />
                </label>
              </div>
            </div>
          </div>
          <div className="mb-32">
            <div className="row_subtitle-16 mb-4">已綁定肚肚POS帳號</div>
            <input
              className="row_input"
              type="text"
              disabled
              value={dudooAppId ?? "未設定"}
            />
            <div className="additional_text">
              一組MENU店+帳號最多只能綁定一組肚肚POS帳號
            </div>
          </div>
          {fetchDudooSyncEnabled && dudooSyncEnabled && renderSyncButton()}
          <div className="block_notice">
            <div className="title">注意事項</div>
            <ul>
              <li>
                為確保兩邊系統資料一致性，請在MENU店+建立預約、修改預約狀態、入座與安排桌位；並僅在肚肚POS進行點餐與結帳。
              </li>
              <li>
                開放預約時間、預約空桌狀態與預約時間長度，將以MENU店+的設定與顯示結果為主。
              </li>
              <li>僅能同步由MENU店+所建立的預約資料。</li>
              <li>
                MENU店+與肚肚POS系統中對應之座位設定需一致方能成功同步預約資料。
                <ul>
                  <li>需啟用MENU店+的「座位圖」功能。</li>
                  <li>
                    MENU店+的「座位圖設定」對應肚肚POS系統中的「區域圖設定」，其數量與名稱需一致。
                  </li>
                  <li>
                    MENU店+的「桌次」對應肚肚POS系統中的「桌號」，其容納人數、桌數與名稱需一致。
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </>
      );
    } else {
      return (
        <div className="content_notActivated">
          尚未開通肚肚POS串接功能，如需申請，請聯繫MENU店+業務。
        </div>
      );
    }
  };

  if (!init) return <div className="pageLoading">讀取中..</div>;
  return (
    <>
      <div className="main overflow dudooSyncSettings">
        {sending && (
          <div className="lightBoxLayer">
            <h4>儲存中</h4>
          </div>
        )}
        <h3>肚肚POS串接設定</h3>
        <h5>
          啟用後，您將可以繼續在MENU店+建立與管理預約進度，預約資料將自動同步至肚肚POS帳號中，讓您接續為顧客進行點餐與結帳。
        </h5>
        <hr />
        {renderEnabled()}
      </div>
      {pos_enabled && (
        <>
          <hr className="main_width" />
          <div className="fix_bottom">
            <button className="btn_submit" onClick={submit}>
              儲存
            </button>
          </div>
        </>
      )}
      {showConfirmModal && (
        <ConfirmPopup
          title={"功能無法啟用"}
          content={
            "請確認MENU店+與肚肚POS系統上的座位對應設定一致，並且啟用座位圖，方可開啟串接功能。"
          }
          submit={() => {
            setShowConfirmModal(false);
            setDudooSyncEnabled(false);
          }}
        />
      )}
    </>
  );
}

export default DudooPosSync;
