import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { browserHistory } from "react-router";
import BookingSystemApi from "../BookingSystemApi";
import { checkDudooTableSync } from "../../../api/posSync";
import "../../../stylesheets/tableMap.css";
import ConfirmPopup from '../../popup/confirmPopup';

let seatCount = 0,
  canvasSeatCount = 0;

const API = new BookingSystemApi();

const DUDOO_SEATS_NOT_MATCHED_TITLE = "座位設定與肚肚POS對應不一致";
const DUDOO_SEATS_NOT_MATCHED_CONTENT =
  "座位設定與肚肚POS系統上的座位設定對應不一致，將導致部分預約資料無法同步。請重新設定，或至肚肚POS系統更新座位。";
const DUDOO_TABLEMAP_DISABLED_TITLE = "設定錯誤";
const DUDOO_TABLEMAP_DISABLED_CONTENT =
  "關閉座位圖將影響與肚肚POS串接的預約資料同步功能。如欲繼續使用肚肚POS請將座位圖啟用以利後續作業。";

const BookingSettingTableMap = (props) => {
  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [sending, setSending] = useState(false);
  const [tableMapList, setTableMapList] = useState([]);
  const [newTabName, setNewTabName] = useState("");
  const [tempEditTabName, setTempEditTabName] = useState("");
  const [editTabIndex, setEditTabIndex] = useState(null);
  const [tableMapSetting, setTableMapSetting] = useState(false);
  const [settingDisable, setSettingDisable] = useState(false);
  const [dudooSyncEnabled, setDudooSyncEnabled] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalTitle, setConfirmModalTitle] = useState(DUDOO_SEATS_NOT_MATCHED_TITLE);
  const [confirmModalContent, setConfirmModalContent] = useState(DUDOO_SEATS_NOT_MATCHED_CONTENT);

  useEffect(() => {
    props.router.setRouteLeaveHook(props.route, () => {
      if (!isSaved) return "您剛剛做的更動尚未儲存，請問您是否要離開?";
    });
  }, [isSaved, props]);

  useEffect(() => {
    const state = browserHistory.getCurrentLocation().state || {};
    const { showConfirmModal } = state;
    
    if (showConfirmModal) {
      setShowConfirmModal(true);
    }
  }, []);

  const getSeatsAndCanvas = useCallback(async () => {
    try {
      seatCount = 0;
      canvasSeatCount = 0;

      await API.getBookingSettingSeats().then((data) => {
        // console.log('getBookingSettingSeats data---', data);
        data.results.map((seat) => {
          seatCount = seatCount + seat.quantity;
          return null;
        });
      });

      await API.getTableMap("").then((data) => {
        setTableMapList(data.table_map_list);
        data.table_map_list.map((canvas) => {
          canvasSeatCount = canvasSeatCount + canvas.table.length;
          return null;
        });
      });

      let canvasEnabled = false,
        settingDisableSetting = false;

      const res = await API.getBookingSetting();
      canvasEnabled = res.canvas_enabled;
      setDudooSyncEnabled(res.pos_enabled);

      if (canvasSeatCount !== seatCount) {
        settingDisableSetting = true;
        canvasEnabled = false;
      }

      setSettingDisable(settingDisableSetting);
      setTableMapSetting(canvasEnabled);
      setLoading(false);
    } catch (err) {
      window.app.alert.setMessage("請稍後再試", "error");
    }
  }, []);

  useEffect(() => {
    getSeatsAndCanvas();
  }, [getSeatsAndCanvas]);

  const toggleTableMapSetting = () => {
    setTableMapSetting((old) => !old);
    setIsSaved(false);
  };

  const enterEditMode = (tabName, index) => {
    setTempEditTabName(tabName);
    setEditTabIndex(index);
  };

  const editTabName = (e) => {
    let txt = e.target.value;
    setTempEditTabName(txt);
  };

  const editModeCancel = () => {
    setTempEditTabName("");
    setEditTabIndex(null);
  };

  const editModeCheck = (index) => {
    const newTableMapList = _.cloneDeep(tableMapList);

    if (tempEditTabName.trim() === "") {
      window.app.alert.setMessage("名稱不可空白", "tip");
      return;
    }
    newTableMapList[index].name = tempEditTabName;
    setTableMapList(newTableMapList);
    setTempEditTabName("");
    setEditTabIndex(null);
    setIsSaved(false);
  };

  const deleteTab = (index) => {
    const newTableMapList = _.cloneDeep(tableMapList);

    newTableMapList.splice(index, 1);
    canvasSeatCount = 0;
    newTableMapList.map((canvas) => {
      canvasSeatCount = canvasSeatCount + canvas.table.length;
      return null;
    });

    if (canvasSeatCount !== seatCount) {
      setSettingDisable(true);
      setTableMapSetting(false);
    }

    setTableMapList(newTableMapList);
    setIsSaved(false);
  };

  const enterTableMap = (tableName) => {
    setIsSaved(true);
    browserHistory.push({
      pathname: "/dashboard/setting/tablemap_setting/tablemap",
      state: {
        activeTab: tableName,
        tabsInfo: tableMapList,
        tableMapSetting,
      },
    });
  };

  const renderTableMapList = () => {
    return tableMapList.map((tab, index) => {
      let hide = index === editTabIndex ? "" : "hide";
      let hideName = index === editTabIndex ? "hide" : "";
      return (
        <div key={index} className="tableMap__list">
          <div className="tableMap__list__title">
            <span className={`${hideName}`}>
              {tab.name}
              <i
                className="editIcon"
                onClick={() => enterEditMode(tab.name, index)}
              />
            </span>
            <div className={`editMode ${hide}`}>
              <div className="editMode__input">
                <input
                  type="text"
                  value={tempEditTabName}
                  placeholder="請命名座位圖"
                  onChange={(e) => editTabName(e)}
                />
              </div>
              <div className="editMode__btns eidtBtns">
                <button
                  className="btn btn-cancel"
                  onClick={() => editModeCancel()}
                >
                  取消
                </button>
                <button
                  className="btn btn-check"
                  onClick={() => editModeCheck(index)}
                >
                  確定
                </button>
              </div>
            </div>
          </div>
          <div className="tableMap__list__edit">
            <i
              className="fa fa-trash"
              aria-hidden="true"
              onClick={() => deleteTab(index)}
            />
          </div>
          <div className="tableMap__list__entry">
            <span onClick={() => enterTableMap(tab.name)}>
              進入座位圖
              <i className="fa fa-chevron-right" aria-hidden="true" />
            </span>
          </div>
        </div>
      );
    });
  };

  const cancel = () => {
    let tableMapAdd = document.getElementById("tableMap__add");
    tableMapAdd.classList.add("hide");
    setNewTabName("");
  };

  const check = () => {
    const newTableMapList = _.cloneDeep(tableMapList);

    let newTab = {
      table: [],
      ele: [],
    };
    let duplicated = false;

    newTableMapList.map((tab) => {
      if (tab.name.trim() === newTabName.trim()) duplicated = true;
      return null;
    });

    if (newTabName.trim() === "") {
      window.app.alert.setMessage("名稱不可空白", "tip");
      return;
    }

    if (duplicated) {
      window.app.alert.setMessage("座位圖名稱重複", "tip");
      return;
    } else {
      newTab.name = newTabName.trim();
      newTableMapList.push(newTab);

      setTableMapList(newTableMapList);
      setIsSaved(false);

      cancel();
    }
  };

  const addTableMap = () => {
    let tableMapAdd = document.getElementById("tableMap__add");

    if (tableMapList.length >= 5) {
      window.app.alert.setMessage("最多五張座位圖", "tip");
      return;
    }

    tableMapAdd.classList.remove("hide");
  };

  const submit = () => {
    setSending(true);
    submitSetting();
  };

  const submitSetting = async () => {
    let canvas_id = [];
    let bookingSetting = JSON.stringify({
      booking_settings: {
        canvas_enabled: tableMapSetting,
      },
    });

    tableMapList.map((canvas) => {
      if (canvas.id) canvas_id.push(canvas.id);
      return null;
    });

    try {
      await API.updateTableMap(tableMapList, canvas_id).then((data) => {
        setTableMapList(data.canvas);
      });

      await API.updateBookingSetting(bookingSetting);

      setSending(false);
      setIsSaved(true);

      if (dudooSyncEnabled) {
        try {
          await checkDudooTableSync();

          if(!tableMapSetting) {
            setShowConfirmModal(true);
            setConfirmModalTitle(DUDOO_TABLEMAP_DISABLED_TITLE);
            setConfirmModalContent(DUDOO_TABLEMAP_DISABLED_CONTENT);
          }
        } catch(error) {
          setShowConfirmModal(true);
          setConfirmModalTitle(DUDOO_SEATS_NOT_MATCHED_TITLE);
          setConfirmModalContent(DUDOO_SEATS_NOT_MATCHED_CONTENT);
        }
      }

      window.app.alert.setMessage("儲存成功", "done");
    } catch (err) {
      window.app.alert.setMessage("請稍後再試", "error");
      setSending(false);
    }
  };

  const disableSwitch = settingDisable ? "settingDisable" : "";
  const noteHide = !settingDisable ? "hide" : "";
  if (loading) return <div className="pageLoading">讀取中..</div>;
  return (
    <>
      <div className="main overflow">
        {sending && (
          <div className="lightBoxLayer">
            <h4>儲存中</h4>
          </div>
        )}
        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 1 }}>
            <h3>座位圖設定</h3>
            <h5>
              您可以依照店內狀況設定座位圖，以更方便檢視不同座位的使用狀況，或進行現場帶位管理。
            </h5>
          </div>
        </div>

        <hr />

        <div className="tableMap">
          <div className="settingTbl">
            <div className="settingTblRow">
              <div className="header fix-header">啟用狀態</div>
              <div className="fix-text">
                <label className={`msSwitch ${disableSwitch}`}>
                  <input
                    type="checkbox"
                    name="autoTable"
                    onChange={() => toggleTableMapSetting()}
                    disabled={settingDisable}
                    checked={tableMapSetting}
                  />
                  <span className="slider" />
                </label>
              {settingDisable ? (
                <p className={`note ${noteHide}`}>
                  座位尚未完全放置於座位圖，故不可開啟
                </p>
              ) : dudooSyncEnabled && !tableMapSetting ? (
                <p className="note">
                  您已啟用肚肚POS串接，若未啟用座位圖可能導致部分預約資料無法同步
                </p>
              ) : (
                ""
              )}
              </div>
            </div>
          </div>

          <div className="tableMap__wrap">
            <div>
              {renderTableMapList()}

              <div id="tableMap__add" className="hide">
                <div className="tableMap__add__title">
                  <input
                    type="text"
                    placeholder="請命名座位圖"
                    value={newTabName}
                    onChange={(e) => setNewTabName(e.target.value)}
                  />
                </div>
                <div className="tableMap__add__btns eidtBtns">
                  <button className="btn btn-cancel" onClick={() => cancel()}>
                    取消
                  </button>
                  <button className="btn btn-check" onClick={() => check()}>
                    確定
                  </button>
                </div>
              </div>
            </div>
            <div className="tableMap__lis tableMap__button">
              <button onClick={() => addTableMap()}>+ 新增座位圖</button>
            </div>
          </div>
        </div>
      </div>

      <hr className="main_width" />

      <div className="fix_bottom">
        <button className="btn_submit" onClick={submit}>
          儲存
        </button>
      </div>

      {showConfirmModal && (
        <ConfirmPopup
          title={confirmModalTitle}
          content={confirmModalContent}
          submit={() => {
            setShowConfirmModal(false);
          }}
        />
      )}
    </>
  );
};

export default BookingSettingTableMap;
