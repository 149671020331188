export default [
  "基市",
  "北市",
  "新北",
  "北縣",
  "桃市",
  "桃縣",
  "竹市",
  "竹縣",
  "苗縣",
  "中市",
  "中縣",
  "彰縣",
  "投縣",
  "雲縣",
  "嘉市",
  "嘉縣",
  "南市",
  "南縣",
  "高市",
  "高縣",
  "屏縣",
  "宜縣",
  "花縣",
  "東縣",
  "連江",
  "金門",
  "澎縣",
];
