import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import BookingSystemApi from '../BookingSystemApi';
import { checkDudooTableSync } from "../../../api/posSync";
import Modal from './BookingSettingSeatsModal';
import { Modal as AntdModal } from 'antd';
import ConfirmPopup from '../../popup/confirmPopup';

const API = new BookingSystemApi();

const BookingSettingSeats = (props) => {
	const [ sending, setSending ] = useState(false);
	const [ cancasRefresh, setCancasRefresh ] = useState(false);
	const [ isSaved, setIsSaved ] = useState(true);
	const [ showModal, setShowModal ] = useState(false);
	const [ tableMapSetting, setTableMapSetting ] = useState(false);
	const [ groups, setGroups ] = useState([]);
	const [ groupSetting, setGroupSetting ] = useState({});
	const [ groupSettingIndex, setGroupSettingIndex ] = useState(null);
	const [ dudooSyncEnabled, setDudooSyncEnabled ] = useState(false);
	const [ showConfirmModal, setShowConfirmModal] = useState(false);

	const getBookingSetting = useCallback(async () => {
		try {
			await API.getBookingSetting().then((data) => {
				setTableMapSetting(data.canvas_enabled);
				setDudooSyncEnabled(data.pos_enabled);
			});

			await API.getBookingSettingSeats().then((data) => {
				setGroups(_.sortBy(data.results, [ 'group', 'max_seat' ]));
			});
		} catch (err) {
			window.app.alert.setMessage('請稍後再試', 'error');
		}
	}, []);

	useEffect(
		() => {
			getBookingSetting();
		},
		[ getBookingSetting ]
	);

	useEffect(
		() => {
			props.router.setRouteLeaveHook(props.route, () => {
				if (!isSaved) return '您剛剛做的更動尚未儲存，請問您是否要離開?';
			});
		},
		[ props, isSaved ]
	);

	const openModal = () => {
		setGroupSetting({
			group: '',
			combinable: false,
			min_seat: 1,
			max_seat: 1,
			open_qty: 0,
			quantity: 0,
			seats: null,
			table_numbers: [],
			table_objs: []
		});
		setGroupSettingIndex(null)
		setShowModal(true);
	};

	const handleOk = (setting) => {
		setShowModal(false);
		const newGroups = _.cloneDeep(groups);


		if (groupSettingIndex !== null) {
			const { table_objs } = newGroups[groupSettingIndex];
			let tableIdx = table_objs.length - 1;
			
			setting.table_objs.map((table) => {
				if (table.idx === null) {
					++tableIdx;
					table.idx = tableIdx;
				}
				return null;
			});

			newGroups[groupSettingIndex] = setting;
			setGroups(_.sortBy(newGroups, [ 'group', 'max_seat' ]));
		} else {
			let tableIdx = 0;

			setting.table_objs.map((table) => {
				if (table.idx === null) {
					table.idx = tableIdx;
					tableIdx++;
				}
				return null;
			});

			newGroups.push(setting);
			setGroups(_.sortBy(newGroups, [ 'group', 'max_seat' ]));
		}
		setGroupSetting({});
		setGroupSettingIndex(null);
		setIsSaved(false);
	};

	const handleCancel = () => {
		setShowModal(false);
		setGroupSetting({});
	};

	const removeGroup = (index) => {
		const newGroups = _.cloneDeep(groups);
		newGroups.splice(index, 1);
		setGroups(newGroups);
		setIsSaved(false);
	};

	const editGroup = (index) => {
		const newGroups = _.cloneDeep(groups);
		const group = newGroups[index];
		setGroupSettingIndex(index);
		setGroupSetting(group);
		setShowModal(true);
	};

	const renderGroupRow = () => {
		return groups.map((group, index) => (
			<GroupRow
				key={index}
				group={group}
				removeGroup={() => removeGroup(index)}
				editGroup={() => editGroup(index)}
			/>
		));
	};

	const handleSubmit = async() => {
		let isValid = true;
		let groupName = '',
			groupMin = null,
			groupMax = null,
			groupCombinable = false;

		let tables = _.sortBy(groups, [ 'group', 'max_seat' ]);

		if (tables.length === 0) return window.app.alert.setMessage('請設定組別', 'error');

		tables.forEach((ts) => {
			if (groupName === '' || groupName !== ts.group) {
				groupName = ts.group;
				groupMin = ts.min_seat;
				groupMax = ts.max_seat;
				groupCombinable = ts.combinable;

				ts.table_objs.forEach((table) => {
					if(table.idx === null || isNaN(table.idx)) {
						window.app.alert.setMessage(`${groupName} 組別 ${table.table_number} 桌次有誤，請重新設定`, 'error');
						isValid = false;
						return;
					}
				})
			} else if (groupName === ts.group) {
				if (groupMin === ts.min_seat && groupMax === ts.max_seat && groupCombinable === ts.combinable) {
					window.app.alert.setMessage(`${groupName} 組別名稱、人數、併桌設定重複`, 'error');
					isValid = false;
					return;
				}

				ts.table_objs.forEach((table) => {
					if(table.idx === null || isNaN(table.idx)) {
						window.app.alert.setMessage(`${groupName} 組別 ${table.table_number} 桌次有誤，請重新設定`, 'error');
						isValid = false;
						return;
					}
				})
			}
		});

		if (!isValid) return;
		settingSubmit();
	}

	const settingSubmit = async () => {
		try {
			setSending(true);

			const groupsRes = await API.saveBookingSettingSeats(groups);
			let cancasRefresh = groupsRes.canvas_refresh_required;

			if (!tableMapSetting) {
				//沒有開啟座位圖不用顯示提醒
				cancasRefresh = false;
			}

			if(dudooSyncEnabled) {
				try {
					await checkDudooTableSync();
				} catch(error) {
					if(!cancasRefresh) setShowConfirmModal(true);
				}
			}

			setGroups(_.sortBy(groupsRes.results, [ 'group', 'max_seat' ]));
			setIsSaved(true);
			setSending(false);
			setCancasRefresh(cancasRefresh);

			if (!cancasRefresh) window.app.alert.setMessage('儲存成功', 'done');
		} catch (err) {
			console.log('settingSubmit err---', err);

			setSending(false);
			window.app.alert.setMessage('請稍後再試', 'error');
		}
	};

	const goCanvas = () => {
		props.router.push('/dashboard/setting/tablemap_setting');
		return;
	};

	return (
    <>
      <div id="seats" className="main overflow">
        {sending && (
          <div className="lightBoxLayer">
            <h4>儲存中</h4>
          </div>
        )}
        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 1 }}>
            <h3>座位設定</h3>
            <h5>
              您可以依據提供服務的座位數量、桌次容納人數、或位置區域建立不同組別。
              每個組別內包含一個到多個同類型的桌次，每個桌次有不同的代號。
            </h5>
            <div className='des-notice'>
              請注意：
							<ul>
								<li>修改及刪除座位將會影響已建立的預約資料，建議在編輯前先進行資料備份。</li>
								<li>若有開啟肚肚POS串接功能，請確保兩邊系統的座位設定需為一致才能同步預約資料。</li>
							</ul>
            </div>
          </div>
        </div>
        <hr />

        <div className="newSeatsSetting">
          <button className="msBtn" onClick={() => openModal()}>
            ＋ 新增組別
          </button>

          <div className="seatsTable">
            <div className="seatsTableRow seatsTableTitleSection">
              <div>組別</div>
              <div>容納人數</div>
              <div>總桌數</div>
              <div>開放線上預約桌數</div>
              <div>開放線上預約併桌</div>
              <div />
              <div />
            </div>
            {renderGroupRow()}
          </div>
        </div>
        {showModal && (
          <Modal
            showModal={showModal}
            handleOk={handleOk}
            handleCancel={handleCancel}
            groupSetting={groupSetting}
            groups={
              groupSettingIndex !== null
                ? groups.filter((_, index) => index !== groupSettingIndex)
                : groups
            }
          />
        )}

        {cancasRefresh && <GoCanvas goCanvas={goCanvas} />}
        {showConfirmModal && (
          <ConfirmPopup
            title="座位設定與肚肚POS對應不一致"
            content="座位設定與肚肚POS系統上的座位設定對應不一致，將導致部分預約資料無法同步。請重新設定，或至肚肚POS系統更新座位。"
            submit={() => {
              setShowConfirmModal(false);
            }}
          />
        )}
        <hr />
      </div>

      <div className="fix_bottom">
        <button
          className="btn_submit"
          // onClick={() => settingSubmit()}
          onClick={handleSubmit}
        >
          儲存
        </button>
      </div>
    </>
  );
};

const GroupRow = ({ group, removeGroup, editGroup }) => {
	const [ expand, setExpand ] = useState(false);

	const toggleExpandSeat = (e) => {
		const icon = e.target;
		if (expand) {
			icon.classList.remove('fa-angle-up');
			icon.classList.add('fa-angle-down');
			setExpand(false);
		} else {
			icon.classList.remove('fa-angle-down');
			icon.classList.add('fa-angle-up');
			setExpand(true);
		}
	};

	const renderTable = () => {
		return group.table_objs
			.filter((table) => table.available === true)
			.map((table) => <span key={table.table_number}>{table.table_number}</span>);
	};

	const removeConfrim =() =>{
		AntdModal.confirm({
			className:"bookingSettingConfirmModal",
			zIndex: 1035,
			title: '確定刪除？',
			content: '刪除後將會影響現有預約，請問是否確認刪除',
			okText: '確定',
			cancelText: '取消',
			onOk(){
				removeGroup()
			}
		})
	}

	return (
    <section className="seatsTableSection">
      <div className="seatsTableRow">
        <div>{group.group}</div>
        <div>
          {group.min_seat}-{group.max_seat}人
        </div>
        <div>{group.quantity}</div>
        <div>{group.open_qty}</div>
        <div>
          {group.combinable ? (
            <span className="onlineOpenIcon" />
          ) : (
            <span className="onlineOpenIcon onlineOpenIcon-disable" />
          )}
        </div>
        <div>
          <i
            className="fa fa-angle-down"
            aria-hidden="true"
            onClick={(e) => toggleExpandSeat(e)}
          />
        </div>
        <div>
          <div className="dropdown">
            <i
              className="fa fa-ellipsis-v"
              aria-hidden="true"
              data-toggle="dropdown"
            />
            <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
              <li onClick={() => editGroup()}>編輯</li>
              <li
                onClick={() => {
                  removeConfrim();
                }}
              >
                刪除
              </li>
            </ul>
          </div>
        </div>
      </div>
      {expand && (
        <div className="expandSeat">
          <p>桌次</p>

          <div className="seatsSection">{renderTable()}</div>
        </div>
      )}
    </section>
  );
};

const GoCanvas = ({ goCanvas }) => {
	return (
    <ConfirmPopup
      title="座位修改成功"
      content="座位修改已經成功儲存，請立即前往修改您的座位圖設定！"
			submitText="前往修改"
			submit={goCanvas}
    />
  );
};

export default BookingSettingSeats;
