import React, { useMemo, useCallback, useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { usePreview } from 'react-dnd-preview';
import $ from 'jquery';
import moment from 'moment';
import _ from 'lodash';
// import dragscroll from '../../libs/vendor/dragscroll';
import BookingSystemApi from '../../booking_settings/BookingSystemApi';
import { WarningTemplate } from '../../new_module/NewModuleComponent/NewModuleWarning';
import { seatAlertTitle } from '../../new_module/utils/data';
import { time } from '../../new_module/utils/data';
import BookingTableBookingRow from './Component/BookingTableBookingCell';
import {
	BookingTableCurrentTime,
	BookingTableCurrentTimeWrap
} from './Component/BookingTableCurrentTime';
import { TimelineWarningTemplate } from '../../new_module/NewModuleComponent/NewModuleWarning';

import { swapReorder, seatedReorder } from '../utils/timelineReorder';

const BookingAPI = new BookingSystemApi();

const colors = [
	'#c05c7e',
	'#ff8080',
	'#bf9fee',
	'#ffb6b9',
	'#8ac6d1',
	'#ffba92',
	'#866ec7',
	'#d3999b',
	'#e7b5f3',
	'#bbded6',
	'#d3aeae',
	'#b5d4bf'
];

const scale = 90;

const BookingTableView = ({
	timelineTables,
	filter,
	showNewAddBookingModal,
	diningTime,
	isToday,
	setStatus,
	date,
	updateData,
	warnings,
	bookings,
}) => {
	const [ tables, setTables ] = useState([]);
	const [ showWarning, setShowWarning ] = useState(false);
	const [ warningTales, setWarningTales ] = useState([]);
	const [ seatedBooking, setSeatedBooking ] = useState({});
	const [ dragBooking, setDragBooking ] = useState({}); //被拖曳的預約
	const [ dragTableName, setDragTableName ] = useState(''); //被拖曳的桌子名稱
	const [ dragBookingIndex, setDragBookingIndex ] = useState(null); //被拖曳的預約在timeline裡的index
	const [ dropBookingIndex, setDropBookingIndex ] = useState(null); //被交換的預約在timeline裡的index
	const [ droppedTable, setDroppedTable ] = useState({}); //drop的位置
	const [ showSeatedWarning, setShowSeatedWarning ] = useState(false); //入座警告
	const [ swappedBooking, setSwappedBooking ] = useState({}); //被交換的預約
	const [ showSwapWarning, setShowSwapWarning ] = useState(false); //交換位置警告
	const [ firstIn, setFirstIn ] = useState(false); //第一次進入timeline 移到現在時間
	const [ todayIn, setTodayIn ] = useState(false);
	const dateRef = useRef(null);

	const ScrollLeftDisMemo = useMemo(
		() => {
			let leftDis = 0,
				topDis = 0;

			// const todayDate = moment().format("YYYY/MM/DD")

			const filterBookings = bookings.filter(
				(booking) => booking.status !== 'no_show' && booking.status !== 'cancel'
			);

			if (filterBookings.length !== 0 && tables.length !== 0) {
				const bookingCellHeight = 75;

				if (dateRef.current !== null && dateRef.current !== date.format('YYYY/MM/DD')) setFirstIn(true);
				if (dateRef.current !== null && dateRef.current === date.format('YYYY/MM/DD')) setFirstIn(false);

				const time = moment(new Date(filterBookings[0]['booking_datetime']));
				leftDis = moment.duration(time.diff(new Date(date))).asHours() * 2 * scale + scale;

				const { seating_json } = filterBookings[0];
				const seating = JSON.parse(seating_json);
				const newTables = _.cloneDeep(tables).map((table) => table.table_number);
				let idxs = [];
				seating.forEach((seat) => {
					const findIndex = newTables.indexOf(seat.table_number);
					idxs.push(findIndex);
				});
				const minIndex = Math.min(...idxs);
				topDis = minIndex * bookingCellHeight;
			}

			return { leftDis, topDis };
		},
		[ bookings, date, tables ]
	);

	useEffect(
		() => {
			$('#tcontainer1 [data-toggle="popover"]').popover({
				html: true,
				trigger: 'click',
				container: '#tcontainer1'
			});
		},
		[ tables ]
	);

	useEffect(
		() => {
			setTables(timelineTables);
		},
		[ timelineTables ]
	);

	useEffect(
		() => {
			const todayDate = moment().format('YYYY/MM/DD');
			const today = date.format('YYYY/MM/DD') === todayDate;

			if (!today && timelineTables.length !== 0 && dateRef.current !== date.format('YYYY/MM/DD')) {
				setFirstIn(true);
			}
			if (today && timelineTables.length !== 0 && dateRef.current !== date.format('YYYY/MM/DD')) {
				setTodayIn(true);
			}
		},
		[ date, timelineTables.length ]
	);

	useEffect(
		() => {
			const $bottom = $('.t-right .bottom');
			const todayDate = moment().format('YYYY/MM/DD');
			const today = date.format('YYYY/MM/DD') === todayDate;

			if (today && todayIn) {
				const currentTimeLeft = moment.duration(moment().format('HH:mm')).asHours() * 2 * scale + scale;
				const bottom_witdth = Math.round($bottom.width() / 2);
				$bottom.scrollLeft(currentTimeLeft - bottom_witdth);
				setTodayIn(false);
				dateRef.current = date.format('YYYY/MM/DD');
			} else if (!today && firstIn) {
				$bottom.scrollLeft(ScrollLeftDisMemo.leftDis);
				$bottom.scrollTop(ScrollLeftDisMemo.topDis);

				if (
					bookings.length !== 0 &&
					moment(bookings[0].booking_datetime).format('YYYY/MM/DD') === date.format('YYYY/MM/DD')
				) {
					setFirstIn(false);
					dateRef.current = date.format('YYYY/MM/DD');
				}
			}
		},
		[ ScrollLeftDisMemo, bookings, date, firstIn, isToday, todayIn ]
	);

	useEffect(
		() => {
			window.setStatus = (targetBooking, newStatus) => {
				const showTime = moment().format().slice(11, 16);
				setStatus(targetBooking, newStatus, updateData, showTime);
				$('[data-toggle="popover"]').popover('hide');
			};

			window.seatedWarning = (booking) => {
				const bookingId = booking.id;
				BookingAPI.checkSeated(bookingId).then((data) => {
					if (data.overlay_table.length !== 0) {
						setShowWarning(true);
						setWarningTales(data.overlay_table);
						setSeatedBooking(booking);
					} else {
						window.setStatus(booking, 'seated');
					}
				});
			};

			return () => {
				delete window['setStatus'];
				delete window['seatedWarning'];
			};
		},
		[ setStatus, updateData ]
	);

	useEffect(
		() => {
			$('body').on('click', function(e) {
				if (
					$(e.target).data('toggle') !== 'popover' &&
					$(e.target).parents('[data-toggle="popover"]').length === 0 &&
					$(e.target).parents('.popover.in').length === 0
				) {
					$('[data-toggle="popover"]').popover('hide');
				}
			});

			$('#tcontainer1').on('click', '.editBooking', (e) => {
				let booking = $(e.currentTarget).data().content;

				showNewAddBookingModal(booking, 'editor');
				$('[data-toggle="popover"]').popover('hide');
			});
		},
		[ showNewAddBookingModal ]
	);

	const timeBookingsMemo = useMemo(
		() => {
			let timeBookings = {},
				bookingId = null;
			const comparedDate = moment(date).format('YYYY-MM-DD');

			tables.forEach((table) => {
				table.timeline.forEach((t) => {
					let bookingDate = moment(t.service_start_time).format('YYYY-MM-DD');
					if (bookingId !== t.id && bookingDate === comparedDate) {
						let hour = t.service_start_time.slice(11, 13),
							min = t.service_start_time.slice(14, 16);
						let hourKey = hour + ':00',
							minKey = hour + ':30';

						bookingId = t.id;

						if (parseInt(min, 10) < 30) {
							if (timeBookings.hasOwnProperty(hourKey)) {
								let res = timeBookings[hourKey].indexOf(t.id);
								if (res === -1) {
									timeBookings[hourKey].push(t.id);
								}
							} else {
								timeBookings[hourKey] = [];
								timeBookings[hourKey].push(t.id);
							}
						} else {
							if (timeBookings.hasOwnProperty(minKey)) {
								let res = timeBookings[minKey].indexOf(t.id);
								if (res === -1) {
									timeBookings[minKey].push(t.id);
								}
							} else {
								timeBookings[minKey] = [];
								timeBookings[minKey].push(t.id);
							}
						}
					}
				});
			});

			return timeBookings;
		},
		[ tables, date ]
	);

	const syncScroll = (e) => {
		$('[data-toggle="popover"]').popover('hide');
		let scrollLeft = e.target.scrollLeft;
		let scrollTop = e.target.scrollTop;
		$('.top').scrollLeft(scrollLeft);
		$('.t-left .bottom').scrollTop(scrollTop);
	};

	const renderTables = useCallback(
		() => {
			const sortTables = tables.filter((t) => filter[t.group] !== false);
			// let sortTables = _.sortBy(tables, [ 'group', 'max_seat' ]).filter((t) => filter[t.group] !== false);
			const colorMap = _.uniq(tables.map((m) => m.group)).reduce((map, groupName, idx) => {
				map[groupName] = colors[idx % colors.length];
				return map;
			}, {});

			let sortGroupTablesArray = [];
			const newTables = JSON.parse(JSON.stringify(sortTables));

			newTables.forEach((t) => {
				const group = t.group;
				let groupTable = {};

				//find group in sortGroupTablesArray
				const groupIndex = sortGroupTablesArray.map((groupName) => groupName.group).indexOf(group);

				if (groupIndex === -1) {
					groupTable = {
						group,
						tables: []
					};
					groupTable.tables.push(t);
					sortGroupTablesArray.push(groupTable);
				} else {
					let targetGroup = sortGroupTablesArray[groupIndex];
					targetGroup.tables.push(t);
				}
			});

			return (
				<div className="bottom">
					{sortGroupTablesArray.map((group, idx) => {
						return (
							<div key={idx} className="groupArea">
								<div className="groupName">
									<p>{group.group}</p>
								</div>
								<div className="tables">
									{group.tables.map((table, id) => {
										return (
											<div
												key={id}
												className="tableLabel"
												style={{
													borderRight: `3px solid ${colorMap[group]}`
												}}
											>
												<p>{table.table_number}</p>
												<p className="sm">
													{table.min_seat}~
													{table.max_seat}
												</p>
											</div>
										);
									})}
								</div>
							</div>
						);
					})}
					<div className="tableLabel" key="x" />
				</div>
			);
		},
		[ filter, tables ]
	);

	const renderCurrentTime = useCallback(
		() => {
			if (isToday) return <BookingTableCurrentTime />;
		},
		[ isToday ]
	);

	const renderCurrentTimeBlock = useCallback(
		() => {
			if (isToday) return <BookingTableCurrentTimeWrap tables={tables} filter={filter} />;
		},
		[ filter, isToday, tables ]
	);

	const renderTimelineTime = useCallback(
		() => {
			return time.map((t, index) => {
				return (
					<div key={index} className="timeCell">
						<span className="tt">
							<div>
								<span>{t}</span>
								<span className="tt-bookingCount">
									{timeBookingsMemo[t] && timeBookingsMemo[t].length + '組'}
								</span>
							</div>
						</span>
					</div>
				);
			});
		},
		[ timeBookingsMemo ]
	);

	const findDropTableBooking = useCallback(
		({ tableInfo }) => {
			const newTables = _.cloneDeep(tables);
			const dropTables = newTables.filter((table) => table.table_number === tableInfo.table_number);
			const newDragBooking = _.cloneDeep(dragBooking);
			const timelineBookings = _.cloneDeep(dropTables[0].timeline).filter(
				(booking) => booking.date === newDragBooking.date
			); //過濾非當日的預約
			

			if (timelineBookings.length === 0) {
				//沒有預約：入座
				setShowSeatedWarning(true);
				return;
			}

			const sameTimeBookingIndex = timelineBookings.findIndex(booking=> booking.time === newDragBooking.time)

			if (sameTimeBookingIndex !== -1) {
				//有相同預約時間的預約：入座or交換
				setSwappedBooking(timelineBookings[sameTimeBookingIndex]);
				setDropBookingIndex(sameTimeBookingIndex);
				setShowSwapWarning(true);
				return;
			}

			setShowSeatedWarning(true);
		},
		[ dragBooking, tables ]
	);

	const startDrag = useCallback(({ booking, tableName, bookingIndex }) => {
		$('[data-toggle="popover"]').popover('hide');
		setDragBooking(booking);
		setDragTableName(tableName);
		setDragBookingIndex(bookingIndex);
	}, []);

	const DraggingEnd = useCallback(
		({ table }) => {
			setDroppedTable(table);
			findDropTableBooking({ tableInfo: table });
		},
		[ findDropTableBooking ]
	);

	const renderRightBottomTl = () => {
		const newDate = new moment(date, 'YYYY/MM/DD');
		const sortTables = tables.filter((t) => filter[t.group] !== false);

		// const newTables = _.sortBy(tables, [ 'group', 'max_seat' ]).filter((t) => filter[t.group] !== false);

		return sortTables.map((table) => {
			return (
				<BookingTableBookingRow
					key={table.table_number}
					table={table}
					newDate={newDate}
					diningTime={diningTime}
					startDrag={startDrag}
					dragBooking={dragBooking}
					dragTableName={dragTableName}
					DraggingEnd={DraggingEnd}
					droppedTable={droppedTable}
				/>
			);
		});
	};

	const cancelSeatedStatusWarning = () => {
		setShowWarning(false);
		setWarningTales([]);
		setSeatedBooking({});
	};

	const confirmSeatedStatusWarning = () => {
		window.setStatus(seatedBooking, 'seated');
		setShowWarning(false);
		setWarningTales([]);
	};

	const renderWarningBooking = useCallback(
		() => {
			if (warnings.length > 0) {
				const tmp = warnings.map((w, idx) => <div key={idx}>{w}</div>);
				return <pre className="bg-danger">{tmp}</pre>;
			}
		},
		[ warnings ]
	);

	const clearTimelineSwapInfo = () => {
		setShowSeatedWarning(false);
		setShowSwapWarning(false);
		setDragBooking({});
		setDragTableName('');
		setDroppedTable({});
		setSwappedBooking({});
		setDragBookingIndex(null);
		setDropBookingIndex(null);
	};

	const swapBooking = useCallback(
		(id, dataSubmit) => {
			BookingAPI.updateBooking(id, dataSubmit)
				.then(() => {
					updateData();
				})
				.catch(() => {
					window.app.alert.setMessage('請重新再試一次', 'error');
				});
		},
		[ updateData ]
	);

	//入座
	const timelineSeated = useCallback(
		({ dragBooking, dragTableName, droppedTable }) => {
			const { newTables, newBooking } = seatedReorder({
				tables,
				dragTableName,
				droppedTable,
				dragBookingIndex,
				dragBooking
			});

			setTables(newTables);

			const dataSubmit = {
				seating_json: JSON.stringify(newBooking.seats),
				booking_datetime: moment(newBooking.booking_datetime).format('YYYY/MM/DD HH:mm'),
				date: moment(newBooking.booking_datetime).format('YYYY/MM/DD')
			};

			swapBooking(newBooking.id, dataSubmit);
		},
		[ dragBookingIndex, swapBooking, tables ]
	);

	//交換位置
	const timelineSwap = useCallback(
		({ dragBooking, dragTableName, droppedTable, swappedBooking }) => {
			const { newTables, newDragBooking, newSwappedBooking } = swapReorder({
				tables,
				dragTableName,
				droppedTable,
				dragBookingIndex,
				swappedBooking,
				dropBookingIndex,
				dragBooking
			});

			setTables(newTables);

			const data = [
				{
					id: newDragBooking.id,
					seats: _.uniqBy(newDragBooking.seats, 'table_number'),
					booking_datetime: newDragBooking.booking_datetime
				},
				{
					id: newSwappedBooking.id,
					seats: _.uniqBy(newSwappedBooking.seats, 'table_number'),
					booking_datetime: newSwappedBooking.booking_datetime
				}
			];

			data.forEach((booking) => {
				const dataSubmit = {
					seating_json: JSON.stringify(booking.seats),
					booking_datetime: moment(booking.booking_datetime).format('YYYY/MM/DD HH:mm'),
					date: moment(booking.booking_datetime).format('YYYY/MM/DD')
				};

				swapBooking(booking.id, dataSubmit);
			});
		},
		[ dragBookingIndex, dropBookingIndex, swapBooking, tables ]
	);

	const cancelWarning = useCallback(() => {
		// console.log('----取消換位子----');
		clearTimelineSwapInfo();
	}, []);

	const confirmSeatedWarning = useCallback(
		() => {
			// console.log('----確定入座----');
			timelineSeated({ dragBooking, dragTableName, droppedTable });
			clearTimelineSwapInfo();
		},
		[ dragBooking, dragTableName, droppedTable, timelineSeated ]
	);

	const confirmSwapWarning = useCallback(
		() => {
			// console.log('----確定換位子----');
			timelineSwap({ dragBooking, dragTableName, droppedTable, swappedBooking });
			clearTimelineSwapInfo();
		},
		[ dragBooking, dragTableName, droppedTable, swappedBooking, timelineSwap ]
	);

	const confirmWarning = useCallback(
		(type) => {
			if (type === 'seated') confirmSeatedWarning();
			if (type === 'swap') confirmSwapWarning();
		},
		[ confirmSeatedWarning, confirmSwapWarning ]
	);

	const TimelineTimeMemo = useMemo(() => renderTimelineTime(), [ renderTimelineTime ]);

	const MyPreview = () => {
		const { display, style } = usePreview();

		if (!display || navigator.maxTouchPoints === 0) {
			return null;
		}

		return (
			<div class="item-list__item" style={style}>
				<p>
					{dragBooking.time} {dragBooking.phone_number}
				</p>
				<p>
					{dragBooking.last_name} {dragBooking.attendance}人
				</p>
			</div>
		);
	};

	return (
		<div>
			<hr />
			{renderWarningBooking()}
			<br />
			<div id="tcontainer1" className="t-container expandWidth">
				<div className="t-left">
					<div className="top">區域/代號</div>
					{renderTables()}
				</div>

				<div className="t-right">
					<div className="shade" />
					<div className="top">
						<div className="timeCell fix-timeCell">
							<div className="tt">
								<div className="tt-t" />
							</div>
						</div>
						{renderCurrentTime()}
						{TimelineTimeMemo}
						<div className="timeCell">
							<span className="tt">
								<div>00:30</div>
							</span>
						</div>
					</div>
					<div className="bottom dragscroll" onScroll={syncScroll} data-nochilddrag="true">
						{renderCurrentTimeBlock()}
						<DndProvider backend={navigator.maxTouchPoints === 0 ? HTML5Backend : TouchBackend}>
							<div className="timeline">{renderRightBottomTl()}</div>
							<MyPreview />
						</DndProvider>
					</div>
				</div>
			</div>

			{/* 入座狀態警告 */}
			{showWarning && (
				<Portal>
					<WarningTemplate
						cancelWarning={cancelSeatedStatusWarning}
						confirmWarning={confirmSeatedStatusWarning}
						title={seatAlertTitle}
					>
						<p>
							桌次
							{warningTales.map((table) => {
								return (
									<span key={table} className="tableName">
										{table}
									</span>
								);
							})}
							有尚未結束的預約
						</p>
						<p>是否確定入座？</p>
					</WarningTemplate>
				</Portal>
			)}

			{/* 交換位置警告 */}
			{showSwapWarning && (
				<Portal>
					<TimelineWarningTemplate
						cancelWarning={cancelWarning}
						confirmWarning={confirmWarning}
						title="預約時間相同警告"
						conflict={true}
					>
						<p>
							<span className="colorRed fontBold">
								{dragBooking.time} {dragBooking.name}
							</span>預約換到 <span className="colorRed fontBold">{droppedTable.table_number}</span>，
						</p>
						<p>
							與{' '}
							<span className="colorRed fontBold">
								{swappedBooking.time} {swappedBooking.last_name}
							</span>{' '}
							預約時間相同，請問您要交換座位或是直接入坐？
						</p>
					</TimelineWarningTemplate>
				</Portal>
			)}

			{/* 交換座位入座警告 */}
			{showSeatedWarning && (
				<Portal>
					<TimelineWarningTemplate
						cancelWarning={cancelWarning}
						confirmWarning={confirmWarning}
						title="確認交換座位"
					>
						<p>
							是否將{' '}
							<span className="colorRed fontBold">
								{dragBooking.time} {dragBooking.name}
							</span>
						</p>
						<p>
							預約座位 <span className="colorRed fontBold">{dragBooking.target_seat.table_number}</span> 換到{' '}
							<span className="colorRed fontBold">{droppedTable.table_number}</span> ？
						</p>
					</TimelineWarningTemplate>
				</Portal>
			)}
		</div>
	);
};

const Portal = ({ children }) => createPortal(children, document.getElementById('modalPortal'));

export default BookingTableView;
