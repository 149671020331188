import axios from 'axios';
import { config } from '../utils/config';

const BookingSettingApi = axios.create({
	baseURL: config.domain,
	withCredentials: true
});

export const checkDudooTableSync = () => BookingSettingApi.get("/dashboard/booking_settings/check_dudoo_tables");

export const bookingRetryDuDooSync = (id) => BookingSettingApi.post(`/dashboard/bookings/${id}/dudoo_retry`);