import React, { useState, useEffect, useCallback } from 'react';
import { browserHistory } from 'react-router';
import moment from 'moment';

import useInterval from '../utils/useInterval';
import { bookingStatus } from '../../new_module/utils/data';
import {tableStatus} from './utils/tableStatus';


export default ({ setStatus, booking, seatedWarning }) => {
	const [ currentTime, setCurrentTime ] = useState(moment());
	const today = moment().format('YYYY/MM/DD');
	const pre = moment(new Date(booking.booking_datetime)).add(-60, 'm').format('YYYY/MM/DD HH:mm');
	const tomorrow = moment(new Date(today)).add(1, 'd').format('YYYY/MM/DD HH:mm');
	let date = browserHistory.getCurrentLocation().query.date;
	const isToday = today === date;
	const [ delay, setDelay ] = useState(isToday ? 1000 : null);
	const [ bookingStatusBtn, setBookingStatusBtn ] = useState(3); //1: 前一天 2:當天時間>預約60分鐘 3:當天時間<=預約60分鐘

	const findStatus = useCallback(
		() => {
			if (currentTime.isAfter(new Date(date), 'date')) {
				//過去: 前一天
				//待確認 確認預約 取消預約 完成預約
				//不顯示 finish show
				setBookingStatusBtn(1);
			} else if (currentTime.isBefore(new Date(pre))) {
				//當天時間>預約60分鐘
				//待確認 確認預約 已到店 取消預約
				setBookingStatusBtn(2);
			} else if (currentTime.isBetween(new Date(pre), new Date(tomorrow), null, '[)')) {
				//當天時間<=預約60分鐘
				//待確認 確認預約 已到店 取消預約 未到店 已入座 完成消費
				setBookingStatusBtn(3);
			}
		},
		[ currentTime, date, pre, tomorrow ]
	);

	useEffect(
		() => {
			if (isToday) {
				setDelay(1000);
			} else {
				setDelay(null);
			}
			findStatus();
		},
		[ isToday, findStatus ]
	);

	useInterval(() => {
		// findStatus();
		setCurrentTime(moment());
	}, delay);

	return (
    <div style={{ textAlign: "right" }}>
      <div className="bookingStatus">
        <button
          style={{ color: tableStatus(booking.status).tableStatusColor }}
          className="bookingStatus-dropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          ● <span style={{ color: "#333" }}>{booking["status_t"]}</span>
        </button>
        <ul
          className="dropdown-menu fix-dropdown-menu"
          aria-labelledby="status"
        >
          {bookingStatus.map((t) => {
            const excludedStatuses = booking.pos_sync_required
              ? ["unconfirmed", "confirmed", "show", "seated", "no_show"]
              : [];

            if (
              booking.status === "seated" &&
              excludedStatuses.includes(t.status)
            ) {
              return null;
            }

            if (bookingStatusBtn === 1) {
              if (
                t.status === "show" ||
                t.status === "no_show" ||
                t.status === "seated"
              ) {
                return null;
              }
            } else if (bookingStatusBtn === 2) {
              if (
                t.status === "no_show" ||
                t.status === "seated" ||
                t.status === "finish"
              ) {
                return null;
              }
            }

            if (t.status === "seated") {
              return (
                <li
                  key={t.status}
                  style={{
                    backgroundColor: tableStatus("seated").tableStatusColor,
                  }}
                >
                  <span onClick={() => seatedWarning(booking)}>
                    {t.status_t}
                  </span>
                </li>
              );
            } else {
              return (
                <li
                  key={t.status}
                  style={{
                    backgroundColor: tableStatus(t.status).tableStatusColor,
                  }}
                >
                  <span onClick={() => setStatus(booking, t.status)}>
                    {t.status_t}
                  </span>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
};




